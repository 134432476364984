<template>
    <div class="app-user-page">

        <div id="app-user-modal" class="modal fade modal-right-side" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="icon-copy ti-user"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>Minha Conta</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">

                        <hr/>

                        <div class="form-group">
                            <label class="col-form-label">E-mail</label>
                            <div class="row">
                                <div class="col-sm-12">
                                    <input type="text" readonly class="form-control-plaintext" :value="userInfo.email">
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div class="form-group">
                            <label class="col-form-label">Nome</label>
                            <div class="row">
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" :value="userInfo.name">
                                </div>
                                <div class="col-sm-3 text-right">
                                    <button type="button" class="btn btn-secondary" @click="showDataModal">Alterar</button>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div class="form-group">
                            <label class="col-form-label">Alterar Senha</label>
                            <div class="row">
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext input-info" value="Inserir nova senha">
                                </div>
                                <div class="col-sm-3 text-right">
                                    <button type="button" class="btn btn-secondary" @click="showPasswordModal">Alterar</button>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div class="form-group">
                            <label class="col-form-label">Excluir Conta</label>
                            <div class="row">
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext input-info" value="Remover permanentemente a sua conta">
                                </div>
                                <div class="col-sm-3 text-right">
                                    <button type="button" class="btn btn-outline-danger" @click="showConfirmRemoveModal">Excluir</button>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="closeAppUserModal">Fechar</button>
                    </div>
                </div>

            </div>
        </div>

        <confirm-remove 
            id="app-user-remove-confirm-modal" 
            question="Excluir conta?"
            text="Ao excluir sua  conta, você perderá o acesso à plataforma e todos os dados e organizações a que pertcence."
            success-message="Conta excluida com sucesso."
            :confirm-method="confirmRemoveAccount"
            :input-confirm="true"
        ></confirm-remove>   

        <div id="app-user-data-modal" class="modal fade modal-right-side" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="icon-copy ti-user"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>Minha Conta</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                       <form id="change-data-form" v-on:submit.prevent="onSubmit" @submit="confirmChangeUserInfo()">
                           <div class="form-group">
                                <label class="col-form-label">Nome completo</label>
                                <input type="text" class="form-control" v-model="userInfo.name" required>
                            </div>
                       </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeDataModal">Cancelar</button>
                        <button type="submit" form="change-data-form" class="btn btn-primary">Alterar</button>
                    </div>
                </div>

            </div>
        </div>

        <div id="app-user-password-modal" class="modal fade modal-right-side" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <div class="modal-title-content">
                                <h3>Alterar Senha</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="change-password-form" v-on:submit.prevent="onSubmit" @submit="confirmChangePassword()">

                            <div class="form-group">
                                <label class="col-form-label">Senha atual</label>
                                <input type="password" autocomplete="current-password" class="form-control" v-model="passwordData.password" required>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Nova senha</label>
                                <input type="password" autocomplete="new-password" class="form-control" v-model="passwordData.newPassword" required>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Confirmar nova senha</label>
                                <input type="password" autocomplete="new-password" class="form-control" v-model="passwordData.confirmNewPassword" required>
                            </div>
                       </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closePasswordModal">Cancelar</button>
                        <button type="submit" form="change-password-form" class="btn btn-primary">Alterar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

import userService from '../services/UserService';
import { eventBus } from '../main';

export default {
    name: 'AppUser',
    components: {
       
    },
    data: () => ({
        passwordData: {},
        userInfo: {
            name: null
        }
    }),
    methods: {
        async confirmRemoveAccount(){
            await userService.removeUser();
            eventBus.$emit('logout');
            this.closeAppUserModal();
        },
        showPasswordModal(){
            this.passwordData = {};
            window.$('#app-user-password-modal').modal();
        },
        async loadUserInfo(){
            this.userInfo = await userService.getUserInfo();
        },
        async showDataModal(){
            window.$('#app-user-data-modal').modal();
        },
        showConfirmRemoveModal(){
            window.$('#app-user-remove-confirm-modal').modal();
        },
        closeAppUserModal(){
            window.$('#app-user-modal').modal('hide');
        },
        closeConfirmRemoveModal(){
            window.$('#app-user-remove-confirm-modal').modal('hide');
        },
        async closeDataModal(){
            await this.loadUserInfo();
            window.$('#app-user-data-modal').modal('hide');
        },
        closePasswordModal(){
            window.$('#app-user-password-modal').modal('hide');
        },
        async confirmChangePassword(){
            if(this.passwordData.newPassword !== this.passwordData.confirmNewPassword){
                eventBus.$emit('showWarnMessage', "Confirmação da nova senha está diferente.");
            } else {
                const userInfo = await userService.getUserInfo();
                await userService.changePassword(
                    userInfo.email,
                    this.passwordData.password, 
                    this.passwordData.newPassword
                );
                this.closePasswordModal();
                eventBus.$emit('showSuccessMessage', "Senha alterada com sucesso.");
            }
        },
        async confirmChangeUserInfo(){
            await userService.changeUserInfo(this.userInfo);
            await this.closeDataModal();
        },
        onSubmit(){}
    },
    async created(){
        this.loadUserInfo();
    }
}

</script>


<style scoped>
    #app-user-password-modal .modal-title-content {
        margin-left: 0px;
    }    
</style>