<template>
    <div>
        
        <menu-title name="Painel do Usuário" icon-class="fa fa-home"></menu-title>   

        <hr> 

        <div class="cards-container">
            <div class="row">
                <div class="col-sm-6 main-col">
                    <div class="row-title">
                        <span>
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            Agenda Diária
                        </span>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h5 class="card-title">
                                            <p>Agenda de <b>Hoje</b></p>
                                        </h5>
                                        <div class="row">
                                            <div class="col-sm-6 col-stats">
                                                <span>{{todayStats.confirmed}}</span>
                                                <p>Confirmaram</p>
                                            </div>
                                            <div class="col-sm-6 col-stats col-stats-expected">
                                                <span>{{todayStats.expected}}</span>
                                                <p>Esperados</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8 stats-event-list">
                                        <h5 class="card-title">Agendados: <b>{{todayInvites.length}} eventos</b></h5>
                                        <div class="stats-event-list-container" v-bind:style="{ 'overflow-y' : todayInvites.length > 2 ? 'scroll' : 'hidden' }">
                                            <ul class="list-group">
                                                <li class="list-group-item" v-for="(item, index) in todayInvites" :key="index">
                                                    <div class="row">
                                                        <div class="col-sm-8 event-list-col">
                                                            <div class="event-list-header">{{item.title}}</div>
                                                            <div class="event-list-footer">
                                                                <span>{{new Date(item.startDate)  | dateFormat('DD/MM/YYYY HH:mm')}}</span>
                                                                <span v-if="item.endDate"> até {{new Date(item.endDate)  | dateFormat('DD/MM/YYYY HH:mm')}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 event-list-col">
                                                            <div class="event-list-header">
                                                                {{item.confirmedGuests}} <span v-if="item.maxGuests">de {{item.maxGuests}}</span>
                                                            </div>
                                                            <div class="event-list-footer">Confirmados</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h5 class="card-title">
                                            <p>Agenda de <b>Amanhã</b></p>
                                        </h5>
                                        <div class="row">
                                            <div class="col-sm-6 col-stats">
                                                <span>{{tomorrowStats.confirmed}}</span>
                                                <p>Confirmaram</p>
                                            </div>
                                            <div class="col-sm-6 col-stats col-stats-expected">
                                                <span>{{tomorrowStats.expected}}</span>
                                                <p>Esperados</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8 stats-event-list">
                                        <h5 class="card-title">
                                            Agendados: <b>{{tomorrowInvites.length}} eventos</b>
                                        </h5>
                                        <div class="stats-event-list-container" v-bind:style="{ 'overflow-y' : tomorrowInvites.length > 2 ? 'scroll' : 'hidden' }">
                                            <ul class="list-group">
                                                <li class="list-group-item"  v-for="(item, index) in tomorrowInvites" :key="index">
                                                    <div class="row">
                                                        <div class="col-sm-8 event-list-col">
                                                            <div class="event-list-header">{{item.title}}</div>
                                                            <div class="event-list-footer">
                                                                <span>{{new Date(item.startDate)  | dateFormat('DD/MM/YYYY HH:mm')}}</span>
                                                                <span v-if="item.endDate"> até {{new Date(item.endDate)  | dateFormat('DD/MM/YYYY HH:mm')}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 event-list-col">
                                                            <div class="event-list-header">
                                                                {{item.confirmedGuests}} <span v-if="item.maxGuests">de {{item.maxGuests}}</span>
                                                            </div>
                                                            <div class="event-list-footer">Confirmados</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 main-col">
                    <div class="row-title">
                        <span>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            Previsto para {{currentMonth}}
                        </span>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <i class="fa fa-user-o" aria-hidden="true"></i>
                                    Visitantes esperados
                                </h5>
                                <div class="row">
                                    <div class="col-sm-6 col-stats">
                                        <span>{{monthStats.scheduled.expected}}</span>
                                        <p>Esperados este mês</p>
                                    </div>
                                    <div class="col-sm-6 col-stats">
                                        <span>{{monthStats.scheduled.confirmed}}</span>
                                        <p>Visitantes confirmaram</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                    Eventos
                                </h5>
                                <div class="row">
                                    <div class="col-sm-4 col-stats">
                                        <span>{{(monthStats.expectedEvents.public || 0)}}</span>
                                        <p>Públicos</p>
                                    </div>
                                    <div class="col-sm-4 col-stats">
                                        <span>{{(monthStats.expectedEvents.private || 0)}}</span>
                                        <p>Privados</p>
                                    </div>
                                    <div class="col-sm-4 col-stats">
                                        <span>{{(monthStats.expectedEvents.private || 0) + (monthStats.expectedEvents.public || 0)}}</span>
                                        <p>Total</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 main-col service-packs">
                    <div class="row-title">
                        <span>
                            <i class="logo-plan" aria-hidden="true"><img src="vendors/images/logo-plan.png" alt=""></i>
                            Pacotes de Serviço
                        </span>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                
                                <h5 class="card-title">Consumo de {{currentMonth}}</h5>
                                
                                <hr/>

                                <!-- Pacote Visitantes -->
                                <span><i class="fa fa-user-o" aria-hidden="true"></i> Visitantes Cadastrados</span>
                                <div class="row">
                                    <div class="col-sm-5 left-col">
                                        <div class="title">Seu pacote prevê</div>
                                        <div class="number">{{selectedAccount.package.numOfVisitors}}</div>
                                        <div class="content">Visitantes por mês</div>
                                    </div>
                                    <div class="col-sm-7 right-col">
                                        <div class="title">Seu consumo</div>
                                        <div class="number"><span>{{monthStats.package.consumed}}</span> Visitas aconteceram</div>
                                        <div class="content">
                                           <div class="progress"><div class="progress-bar" v-bind:style="consumedVisitingStatusBarStyle"></div></div>
                                           <b>{{selectedAccount.package.numOfVisitors - monthStats.package.consumed}}</b> Visitas restantes
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Pacote SMS -->
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> Disparo de SMS</span>
                                <div class="row">
                                    <div class="col-sm-5 left-col">
                                        <div class="title">Seu pacote prevê</div>
                                        <div class="number">{{selectedAccount.package.numOfSMSs}}</div>
                                        <div class="content">Disparados disponíveis por mês</div>
                                    </div>
                                    <div class="col-sm-7 right-col">
                                        <div class="title">Seu consumo</div>
                                        <div class="number"><span>0</span> Disparos efetuados</div>
                                        <div class="content">
                                           <div class="progress"><div class="progress-bar" style="width:0%;background-color: #00BDFF;"></div></div>
                                           <b>{{(selectedAccount.package.numOfSMSs || 0) - 0}}</b> Disparados disponíveis
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import dashboardService from '../services/DashboardService';
    import dateUtils from '../utils/DateUtils';
    import MenuService from '../services/MenuService';
    import selfRegisterService from '../services/SelfRegisterService';

    export default {
        name: 'Home',
        data: () => ({
            selectedAccount: { package: {} },
            monthStats: { scheduled: { expected: 0, confirmed: 0 }, package: { total: 0, consumed: 0}, expectedEvents: { public: 0, private: 0} },
            todayStats: { expected: 0, confirmed: 0 },
            tomorrowStats: { expected: 0, confirmed: 0 },
            todayInvites: [],
            tomorrowInvites: [],
            consumedVisitingStatusBarStyle: "width:0%;background-color: #00BDFF;",
            currentMonth: ""
        }),
        methods: {
            buildConsumedVisitingStatusBarStyle(){
                const percent = (( this.monthStats.package.consumed / this.selectedAccount.package.numOfVisitors) * 100).toFixed(2);
                let bgColor = '#00BDFF';
                if(percent >= 75 && percent < 100){
                    bgColor = '#F6A609';
                } else if (percent >= 100){
                    bgColor = '#DD3416';
                }
                this.consumedVisitingStatusBarStyle =  `width:${percent}%;background-color: ${bgColor};`;
            },
            async loadMonthStats(){
                const date = new Date();
                this.monthStats = await dashboardService.getMonthStats(this.selectedAccountId, date.getFullYear(), date.getMonth() + 1);
            },
            async loadDayStats(){
                const date = new Date();
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                this.todayStats = await dashboardService.getDayStats(this.selectedAccountId, year, month, day);
                this.tomorrowStats = await dashboardService.getDayStats(this.selectedAccountId, year, month, day + 1);
            },
            async loadInvites(){
                const date = new Date();
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                this.todayInvites = await selfRegisterService.getInvitesByDay(this.selectedAccountId, year, month, day);
                this.tomorrowInvites = await selfRegisterService.getInvitesByDay(this.selectedAccountId, year, month, day + 1);
            }
        },
        async created() {

            const menuService = new MenuService(this.$router);

            if(!this.$store.state.userPermissions.includes('MANAGE_ACCOUNT')){
                menuService.goToWelcomePage();
                return;
            }

            this.selectedAccount = this.$store.state.selectedAccount;
            this.selectedAccountId = this.selectedAccount.id;
            
            await this.loadMonthStats();
            await this.loadDayStats();
            
            this.buildConsumedVisitingStatusBarStyle();
            this.currentMonth = dateUtils.getCurrentMonth();

            await this.loadInvites();

        }
    }

</script>

<style scoped>

    .row {
        margin-right: 0px;
        margin-left: 0px;
    }

    .cards-container {
        padding: 3px 10px;
    }

    .cards-container .main-col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card {
        margin: 5px 0px;
        height: 200px;
        width: 100%;
        text-align: left;
        background: rgba(255, 255, 255, 0.5);
        border: 0px;
        box-shadow: 0 0 0 0;
        border-radius: 10px;
    }

    .service-packs .card {
         height: 100%;
    }

    .service-packs .card .row {
        padding-bottom: 25px;
    }

    .card .card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-bottom: 20px;
    }

    .card .card-title p {
        margin: 0;
        margin-bottom: 6px;
    }

    .stats-event-list .card-title {
        padding-bottom: 2px;
    }

    .stats-event-list .stats-event-list-container {
        overflow-y: scroll;
        height: 120px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .stats-event-list .list-group-item {
        border-top: 0px solid rgba(0, 0, 0, 0.125);
        border-left: 0px solid rgba(0, 0, 0, 0.125);
        border-right: 0px solid rgba(0, 0, 0, 0.125);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .card .card-title span {
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .row-title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #161616;
        padding-bottom: 10px;
        padding-left: 3px;
    }

    .row-title i {
        padding-right: 5px;
    }

    .card .col-stats {
        padding-right: 3px;
        padding-left: 3px;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #161616;
        width: 100%;
    }

    .card .col-stats p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .card .col-stats-expected {
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 0.2px;
        color: hsl(210, 6%, 34%);
    }

    .stats-event-list {
        border-left: 1px solid rgba(0, 0, 0, 0.125);
        padding-right: 0px;
    }

    .stats-event-list .event-list-header {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #161616;
         display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    
    .stats-event-list .event-list-footer {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
        padding-top: 5px;
    }

    .stats-event-list .event-list-col {
        padding-left: 8px;
        padding-right: 2px;
    }

    .card-body .row {
        height: 100%;
    }

    .list-group li {
        background: rgba(255, 255, 255, 0.5);
    }

    .list-group-item {
        padding: 10px 0px;
    }

    .btn-link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #1086FF;
    }

    .stats-event-list .btn-link {
        padding-top: 10px;
    }

    .service-packs .left-col {
        padding-left: 0px;
        padding-right: 5px;
    }

    .service-packs .right-col {
        border-left: 1px solid rgba(0, 0, 0, 0.125);
        padding-right: 0px;
        padding-left: 10px;
    }

    .service-packs .right-col,
    .service-packs .left-col {
        margin-top: 18px;
    }

    .service-packs .right-col .title,
    .service-packs .left-col .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-bottom: 15px;
    }

    .service-packs .right-col .number {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .service-packs .left-col .number,
    .service-packs .right-col .number span {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .service-packs .right-col .number span {
        color: #161616;
    }   

    .service-packs .right-col .progress {
        background: #E5E5E5;
        border-radius: 2px;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
        height: 18px;
    }

    .service-packs .left-col .content {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .service-packs .right-col .content {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }
    
    .service-packs .card-title {
        padding-bottom: 0px;
    }

    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0px solid rgba(0, 0, 0, 0.125);
    }

    @media (max-width: 1680px) { 
        .card .col-stats-expected, 
        .card .col-stats {
            font-size: 38px;
        }
        .card .col-stats-expected p, 
        .card .col-stats p {
            font-size: 12px;
        }
    }

    @media (max-width: 1460px) { 
        
        .row-title {
            font-size: 18px;
        }

        .card .col-stats-expected, 
        .card .col-stats {
            font-size: 30px;
        }

        .card .col-stats-expected p, 
        .card .col-stats p {
            font-size: 11px;
        }

        .card .card-title {
            font-size: 14px;
            padding-bottom: 0px;
        }

        .cards-container .card-body {
            padding: 6px;
        }

    }

    @media (max-height: 780px) {
        .service-packs .card {
            height: 410px;
            overflow-y: scroll;
        }
        .card-body .row {
            height: 50%;
        }
    }

</style>
