import apiService from './ApiService';

class DashboardService {

    constructor () {}

    async getMonthStats(accountId, year, month){
        const response = await apiService.get(`/selfregister/v1/${accountId}/dashboard/monthStats?year=${year}&month=${month}`);
        return response.data;  
    }

    async getDayStats(accountId, year, month, day){
        const response = await apiService.get(`/selfregister/v1/${accountId}/dashboard/dayStats?year=${year}&month=${month}&day=${day}`);
        return response.data;  
    }

}

export default new DashboardService();