
export default {
    bind: function (el) { 

        //TODO: VER UMA FORMA DE FAZER SÓ COM CSS E REMOVER ESSA DIRECTIVA 

        function apllyNewHeight(){

            var height = window.innerHeight;
            var percent = 8;

            if(height > 750){
                percent = 7.5;
            } 

            if(height > 900){
                percent = 7;
            } 

            el.getElementsByTagName("tbody")[0].style.height = Math.round((height / 100) * percent) + "vh";

        }
        
        el.setAttribute("class", el.getAttribute("class") + " scrollable-table");

        apllyNewHeight();
        
        window.addEventListener('resize', (event) => {
            event.preventDefault();
            apllyNewHeight();
        }, { passive: true});

    }
}