<template>
    <div>
        
        <div class="header-container">
            
            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>  

            <div class="modal-button">
                <button type="button" class="btn btn-primary" @click="showCreateResponsiblesModal">Criar Lista de responsáveis</button>
            </div>

            <div class="count-container"> 
                <span class="count-number">{{responsibleLists.length}}</span>
                <span class="count-text">Listas</span>
            </div>

            <hr> 

        </div>

        <div class="list-container">
            <table class="table" v-scrollable-table>
                <thead>
                    <tr>
                        <th style="width:27%">Nome Lista</th>
                        <th style="width:70%">Responsáveis</th>
                        <th style="width:3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in responsibleLists" :key="index">
                        <td style="width:27%">{{item.name}}</td>
                        <td style="width:70%">{{item.responsibles.map( i => i.name).join(", ")}}</td>
                        <td style="width:3%" class="dropdown-actions">
                            <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a class="dropdown-item" href="#" @click="showEditResponsibleList(item)">
                                    <i class="fa fa-pencil"></i> Editar
                                </a>
                                <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)">
                                    <i class="fa fa-trash"></i> Excluir
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade create-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>{{ this.responsibleList.id ? 'Editar' : 'Criar' }} Lista</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="responsible-list-form" v-on:submit.prevent="onSubmit" @submit="createResponsibleList">
                            <div class="row">
                                <div class="col-sm">
                                        
                                    <div class="form-row form-title">
                                        <h5>Dados da lista</h5>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">Nome Lista</label>
                                        <input type="text" class="form-control" v-model="responsibleList.name" required>
                                    </div>

                                    <div class="card" v-if="!addingResponsibles">
                                        
                                        <div class="card-header" >
                                            <div class="row">
                                                <div class="col-sm">
                                                    <h5>Responsáveis ({{responsibleList.responsibles.length}})</h5>
                                                </div>
                                                <div class="col-sm">
                                                    <a type="button" class="btn btn-link float-right" href="#" @click="addResponsibles">
                                                        Adicionar Responsável
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="card-body scrollable" >
                                            <p class="no-responsibles" v-if="!responsibleList.responsibles.length">Ainda sem responsáveis</p>
                                            <div class="row responsibles-list" v-for="(item, index) in responsibleList.responsibles" :key="index">
                                                <div class="col-sm-11">
                                                    <div class="responsible-name">{{item.name}}</div>
                                                    <div class="responsible-email" v-if="item.email">
                                                        <span><i class="fa fa-envelope-o"></i></span>
                                                        <span>{{item.email}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1 remove-action col-vert-center">
                                                    <a href="#" @click="removeResponsible(item)"><i class="fa fa-trash"  ></i></a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="card" v-if="addingResponsibles">
                                        <div class="card-body">
                                            <form id="responsibles-form" v-on:submit.prevent="onSubmit" @submit="addResponsible">
                                                <div class="form-group" style="padding-top: 4px">
                                                    <h5>Adicionar Responsável</h5>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-form-label">Nome do responsável</label>
                                                    <input type="text" class="form-control" v-model="responsible.name" required>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-form-label">E-mail</label>
                                                    <input type="email" class="form-control" v-model="responsible.email" required>
                                                </div>
                                                <div class="row adding-responsibles-btns">
                                                    <div class="col-sm-8">
                                                        <a type="button" class="btn btn-link float-right" href="#" @click="closeAddingResponsible">
                                                            Cancelar
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <button type="submit" form="responsibles-form" class="btn btn-link float-right" href="#">
                                                            Adicionar
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-footer">
                                            <h5>Responsáveis ({{responsibleList.responsibles.length}})</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer" v-show="!addingResponsibles">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" form="responsible-list-form" class="btn btn-primary">{{ this.responsibleList.id ? 'Editar' : 'Salvar' }}</button>
                    </div>
                </div>
            </div>
        </div>


        <confirm-remove 
            id="responsible-list-remove-confirm-modal" 
            question="Excluir lista?"
            success-message="Lista excluida com sucesso."
            :input-confirm="true"
            :confirm-method="confirmRemoveResponsibleList"
        ></confirm-remove>

    </div>
</template>

<script>

    import selfRegisterService from '../services/SelfRegisterService';
    import { eventBus } from '../main';

    export default {
        name: 'Responsibles',
        data: () => ({
            responsibleLists: [],
            responsibleList: {
                responsibles: []
            },
            responsible: {},
            addingResponsibles: false
        }),
        methods: {
            removeResponsible(item){
                this.responsibleList.responsibles = this.responsibleList.responsibles.filter( i => i.email !== item.email );
            },
            addResponsibles(){
                this.responsible = {};
                this.addingResponsibles = true;
            },
            addResponsible(){
                
                const responsible = {
                    name: this.responsible.name.trim(),
                    email: this.responsible.email.trim()
                };

                if (!this.responsibleList.responsibles.some(r => r.email.toUpperCase() === responsible.email.toUpperCase() || r.name.toUpperCase() === responsible.name.toUpperCase())) {
                    this.responsibleList.responsibles.push(responsible);
                } else {
                    eventBus.$emit('showWarnMessage', `Responsável com o E-mail: ${responsible.email} ou Nome: ${responsible.name} já existe na lista!`);
                    return;
                }
                
                this.closeAddingResponsible();
            },
            closeAddingResponsible(){
                this.addingResponsibles = false;
            },
            async confirmRemoveResponsibleList(){
                const responsibleList = this.selectedResponsibleListToRemove;
                await selfRegisterService.removeResponsibleList(responsibleList.accountId, responsibleList.id);
                await this.loadResponsibleLists();
            },
            showConfirmRemoveModal(item){
                this.selectedResponsibleListToRemove = item;
                window.$('#responsible-list-remove-confirm-modal').modal();
            },
            showCreateResponsiblesModal(){
                this.responsibleList = {
                    responsibles: []
                };
                window.$('.create-modal').modal();
            },
            showEditResponsibleList(item){
                this.responsibleList = Object.assign({}, item);
                window.$('.create-modal').modal();
            },
            async loadResponsibleLists(){
                this.responsibleLists = await selfRegisterService.getResponsibles(this.selectedAccountId);
            },
            async createResponsibleList(){
                
                if(!this.responsibleList.responsibles.length){
                    eventBus.$emit('showWarnMessage', "Lista de responsáveis não pode estar vazia.");
                    return;
                }

                const responsibleListData = Object.assign({}, this.responsibleList);

                if(responsibleListData.id){
                    await selfRegisterService.updateResponsibleList(responsibleListData);
                } else {
                    await selfRegisterService.createResponsibleList(this.selectedAccountId, responsibleListData);
                }

                eventBus.$emit('showSuccessMessage', "Lista criada com sucesso.");
                this.loadResponsibleLists();
                window.$('.create-modal').modal('hide');
            },
            onSubmit(){}
        },
        async created() {
            this.selectedAccount = this.$store.state.selectedAccount;
            this.selectedAccountId = this.$store.state.selectedAccount.id;
            this.loadResponsibleLists();
            this.responsibles = []
        }
    }

</script>

<style scoped>

    .create-modal #responsible-list-form .scrollable {
        overflow-y: scroll;
        height: 600px;
    }

    .no-responsibles {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-top: 58px;
    }

    .card {
        height: 100%;
    }

    .card-header, .card-footer {
        background-color: #ffffff;
    }

    .card-body {
        padding-top: 11px;
    }

    .btn-link {
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #1086FF;
    }

    .card-footer {
        margin-top: 30px;
    }

    .create-modal #responsible-list-form .card {
        margin-top: 25px;
    }

    .create-modal #responsible-list-form .scrollable {
        overflow-y: scroll;
        height: 360px;
    }

    .adding-responsibles-btns {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-bottom: 60px;
        padding-right: 22px;
        width: 100%;
    }

    #responsibles-form {
        height: 50px;
    }

    .responsibles-list {
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #E5E5E5;
        margin-right: 0px;
        margin-left: 0px;
    }

    .responsibles-list .remove-action {
        line-height: 16px;
        padding-left: 10px;
    }

    .responsibles-list .col-vert-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .responsible-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .responsible-email {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .responsible-email i {
        padding-right: 5px;
    }

    .responsibles-list .col-vert-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

</style>
