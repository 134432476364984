import hash from 'js-sha512';
import apiService from './ApiService';

class AuthService {

    constructor() {}

    async requestResetPassword(login) {
        await apiService.post('/auth/v1/user/requestResetPassword', { login });
    }

    async resetPassword(reqId, login, password) {
        await apiService.post('/auth/v1/user/resetPassword', { reqId, password: hash.sha512(login + password) });
    }

    async logout() {
        await apiService.get('/auth/v1/logout');
    }

    async login(login, password) {

        let loginRequestRes;
        let challengeRes;

        try {
            await apiService.post(
                '/auth/v1/login/loginRequest', { login }, { headers: { 'content-type': 'application/json' } }
            );
        } catch (e) {
            if (e.response && e.response.status !== 401) {
                throw new Error("Erro ao executar loginRequest");
            } else {
                console.error(e);
                loginRequestRes = e.response.data;
            }
        }

        const { challenge, salt } = loginRequestRes;

        try {

            await apiService.post(
                '/auth/v1/login/challengeResponse', {
                    response: hash.sha512(challenge + hash.sha512(login + password) + salt),
                    challenge,
                    login
                }, {
                    headers: { 'content-type': 'application/json' },
                    withCredentials: true
                }
            );

        } catch (e) {
            challengeRes = e.response;
        }

        if (challengeRes && challengeRes.status !== 200) {
            throw new Error(`Erro ao executar challengeResponse. Status: ${challengeRes.status}`);
        }

    }

}

export default new AuthService();