import apiService from './ApiService';

class SelfRegisterService {

    constructor () {}

    async getFlows(accountId){
        const response = await apiService.get(`/selfregister/v1/${accountId}/flows`);
        return response.data;    
    }

    async getGuests(accountId, inviteId){ 
        const inviteQueryParam = inviteId ? `?inviteId=${inviteId}` : '';
        const response = await apiService.get(`/selfregister/v1/${accountId}/guests${inviteQueryParam}`);
        return response.data;    
    }

    async getInvitesByDay(accountId, year, month, day){ 
        const response = await apiService.get(`/selfregister/v1/${accountId}/invites?year=${year}&month=${month}&day=${day}`);
        return response.data;    
    }

    async getInvites(accountId){ 
        const response = await apiService.get(`/selfregister/v1/${accountId}/invites`);
        return response.data;    
    }

    async getInvite(accountId, inviteId){ 
        const response = await apiService.get(`/selfregister/v1/${accountId}/invites/${inviteId}`);
        return response.data;    
    }

    async removeInvite(accountId, inviteId){
        await apiService.delete(`/selfregister/v1/${accountId}/invites/${inviteId}`);
    }

    async createInvite(accountId, data){
        await apiService.post(`/selfregister/v1/${accountId}/invites`, data, {  headers : { 'content-type': 'application/json' } });
    }

    async updateInvite(data){
        await apiService.put(`/selfregister/v1/${data.accountId}/invites/${data.id}`, data, {  headers : { 'content-type': 'application/json' } });
    }    

    async getResponsibles(accountId){ 
        const response = await apiService.get(`/selfregister/v1/${accountId}/responsibles`);
        return response.data;    
    }   

    async createResponsibleList(accountId, data){
        await apiService.post(`/selfregister/v1/${accountId}/responsibles`, data, {  headers : { 'content-type': 'application/json' } });
    }

    async updateResponsibleList(data){
        await apiService.put(`/selfregister/v1/${data.accountId}/responsibles/${data.id}`, data, {  headers : { 'content-type': 'application/json' } });
    }  

    async removeResponsibleList(accountId, listId){
        await apiService.delete(`/selfregister/v1/${accountId}/responsibles/${listId}`);
    }

    async removeGuest(accountId, guestId){
        await apiService.delete(`/selfregister/v1/${accountId}/guests/${guestId}`);
    }   
    
    async getGuestPhoto(accountId, guestId){ 
        const response = await apiService.get(`/selfregister/v1/${accountId}/guests/${guestId}/media`, { responseType: "arraybuffer" });
        return response.data;    
    }  

    

}

export default new SelfRegisterService();