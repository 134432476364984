<template>

  <div id="app">

    <router-view name="fullscreen"/>

    <div class="pre-loader" >
        <div class="pre-loader-box">
            <div class="loader-logo"><img src="vendors/images/logo-icon.png" alt=""></div>
            <div class='loader-progress' id="progress_div">
                <div class='bar' id='bar1'></div>
            </div>
            <div class='percent' id='percent1'>0%</div>
            <div class="loading-text">
                Carregando ...
            </div>
        </div>
    </div>
    
    <div v-if="$store.state.selectedAccount">

        <app-header v-if="!$route.params.fullscreen"/>

        <app-menu v-if="!$route.params.fullscreen"/>

        <div class="mobile-menu-overlay"></div>
            
        <router-view class="main-container"/>
        
    </div> 

    <!-- MESSAGE MODAL -->
    <div class="modal fade" id="msg-modal" tabindex="-1" role="dialog" aria-labelledby="msgModalTitle" aria-hidden="true" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body text-center font-14">
                    <h5 v-if="messageType === 'success'" class="mb-20">Sucesso</h5>
                    <h5 v-if="messageType === 'error'" class="mb-20">Erro</h5>
                    <h5 v-if="messageType === 'warn'" class="mb-20">Alerta</h5>
                    <div v-if="messageType === 'success'" class="mb-10 text-center"><img src="vendors/images/success.png"></div>
                    <div v-if="messageType === 'error'" class="mb-10 text-center"><img src="vendors/images/cross.png"></div>
                    <div v-if="messageType === 'warn'" class="mb-10 text-center"><img src="vendors/images/caution-sign.png"></div>
                    <p style="word-wrap: break-word;" v-html="message"></p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-primary" @click="closeMsgModal">Ok</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal fade modal-right-side" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
        <login-register/>
    </div>

    <div class="modal fade modal-right-side" id="forgot-pass-modal" tabindex="-1" role="dialog" aria-labelledby="forgotPassModalLabel" aria-hidden="true">
        <forgot-pass/>
    </div>

    <div class="modal fade modal-right-side" id="reset-pass-modal" tabindex="-1" role="dialog" aria-labelledby="resetPassModalLabel" aria-hidden="true">
        <reset-pass/>
    </div>

    <app-user/>

  </div>
</template>

<script>

import AppMenu from "./components/AppMenu.vue";
import AppHeader from "./components/AppHeader.vue";
import LoginRegister from "./components/LoginRegister.vue";
import ForgotPass from "./components/ForgotPass.vue";
import ResetPass from "./components/ResetPass.vue";
import AppUser from "./components/AppUser.vue";

import MenuService from './services/MenuService';
import userService from './services/UserService';
import managerService from './services/ManagerService';

import { eventBus } from './main';

export default {
    name: 'App',
    data: () => ({
        message : "",
        messageType: "",
        callbackMessage: () => {}
    }),
    components: {
        'app-header': AppHeader, 
        'app-menu': AppMenu,
        'app-user': AppUser,
        'login-register': LoginRegister,
        'forgot-pass': ForgotPass,
        'reset-pass': ResetPass
    },
    methods: {
        registerAlertMessages(){
            eventBus.$on('showErrorMessage', (message, callback) => this.showMessage('error', message, callback));
            eventBus.$on('showSuccessMessage', (message, callback) => this.showMessage('success', message, callback));
            eventBus.$on('showWarnMessage', (message, callback) => this.showMessage('warn', message, callback));
        },
        showMessage(messageType, message, callback){
            this.callbackMessage = callback || (() => {});
            this.messageType = messageType;
            this.message = message;
            window.$('#msg-modal').modal();
        },
        setEvetntBus(){
            eventBus.$on('showLoadingModal', () => {
                window.$( "<div id='searching-loader' class='loader-background'><span class='loader'>Carregando ...</span></div>").appendTo("#app");
            });
            eventBus.$on('closeLoadingModal', () => { 
                window.$('#searching-loader').remove();
            });
        },
        closeMsgModal(){
            this.callbackMessage();
            window.$('#msg-modal').modal('hide');
        }
    }, 
    async created(){
        
        this.setEvetntBus();
        this.registerAlertMessages();
        const menuService = new MenuService(this.$router);
        const login = localStorage.getItem('auth-user');
        
        if(login){

            const userInfo = await userService.getUserInfo();
            this.$store.commit('updateUserInfo', userInfo );

            const config = await managerService.loadManagerConfig();
            this.$store.commit('updateManagerConfig', config );

            if(userInfo.accounts.length){
                const accounts = await managerService.getAccounts();   
                const firstAccount = accounts[0];                
                const userPermissions = await userService.getUserPermissions(firstAccount.id, userInfo.id);
                this.$store.commit('updateSelectedAccount', firstAccount );
                this.$store.commit('updateUserPermissions', userPermissions );
            } else {
                menuService.goToOrganization();
            }
            
        } 

    }
}

</script>

<style>

    body {
         overflow: hidden; 
    }
    
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .btn-primary {
        background-color:rgb(2, 117, 216);
        border-color: rgb(2, 117, 216);
    }

    .btn-primary:hover {
        background-color:rgb(2, 117, 216);
        border-color: rgb(2, 117, 216);
    }

    .btn-primary:focus, .btn-primary.focus {
        background-color:rgb(2, 117, 216);
        border-color: rgb(2, 117, 216);
    }

    .page-item.active .page-link {
        background-color:rgb(2, 117, 216);
        border-color: rgb(2, 117, 216);
    }

    .page-link {
        color:rgb(2, 117, 216);
    }

    .pre-loader .bar {
        width: 0%;
        height: 8px;
        display: block;
        background: #1086FF;
    }

    .main-container {
        padding-top: 105px;
        padding-bottom: 0px;
        height: 100vh !important;
    }

    .footer-container {
        padding-top: 0px;
        padding-bottom: 0px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .footer-container, .main-container  {
        padding-left: 310px;
        padding-right: 10px;
    }

    #msg-modal {
        z-index: 1100;
    }

    .custom-file-input:focus, .custom-select:focus, .form-control:focus {
        color: #131e22;
        border-color: rgb(2, 117, 216);
    }

    .footer-wrap {
        padding: 5px 10px;
        margin: 8px 0px;
        font-size: 14px;
    }

    .action-btn .btn, .modal-footer .btn {
        padding: 4px 8px;
        font-size: 14px;
    }

    .brand-logo a .svg, 
    .brand-logo a img {
        max-width: 195px !important;
        padding: 0px 0px 0px 35px !important;
    }

    .brand-logo a .svg, 
    .brand-logo a img {
        max-width: 205px !important;
    }

    .table td {
        font-size: 14px !important;
    }

    .badge {
        font-size: 13px !important;
    }

    .loader-logo {
        width: 300px;
    }

    .pre-loader .loader-progress {
        max-width: 300px;
    }

   

    @media (max-width: 1400px) and (min-width: 1301px) {

        h1, h2, h3, h4, h5, h6 {
            font-weight: 500;
        }

        .h4, h4 {
            font-size: 16px;
        }

        .form-group {
            margin-bottom: 10px;
        }

        .custom-file-input, 
        .custom-file-label, 
        .custom-select, 
        .form-control {
            height: 35px;
        }

        .table td {
            font-size: 12px !important;
        }

        .badge {
            font-size: 12px !important;
        }

        .action-btn .btn, .modal-footer .btn {
            padding: 4px 8px;
            font-size: 13px;
        }

        .main-container, 
        .footer-container {
            padding-left: 230px;
            padding-right: 10px;
        }

        .sidebar-menu .dropdown-toggle,
        .sidebar-menu .submenu li a {
            font-size: 11px !important;
            color: #161616;
        }

        .brand-logo a .svg, 
        .brand-logo a img {
            max-width: 185px !important;
        }

        .sidebar-menu .dropdown-toggle:after {
            font-size: 14px;
        }

        .sidebar-menu .dropdown-toggle, .sidebar-menu .submenu li a {
            font-size: 14px !important;
            color: #161616;
        }
        .sidebar-menu .dropdown-toggle .micon {
            width: 10px;
            font-size: 20px;
        }

        .main-container, .footer-container {
            padding-left: 310px;
        }

    }

    .brand-logo a { 
        padding: 0;
    }

    input[type=checkbox] {
        transform: scale(1.3);
    }

    .list-container {
        padding: 25px;
        padding-top: 0px;
        padding-right: 15px;
        height: 100vh;
    }   

    .list-container > table > tbody {
        background: #F9F9FA;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
        text-align: left;
    }

    .list-container > table > thead > tr > th {
        border-top: 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #52575C;
        text-align: left;
    }

    .header-container {
        
    }

    .actions .fa {
        font-size: 18px;
    }

    .count-container {
        text-align: left;
        padding-top: 27px;
        padding-left: 30px;
    }

    .count-container .count-number {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #161616;
    }

    .count-container .count-text {
        padding-left: 10px;
        padding-right: 23px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .btn, .modal-footer .btn, .action-btn .btn {
        box-shadow: 0px 8px 16px rgba(161, 175, 189, 0.16);
        border-radius: 8px;
        padding: 8px 28px;
        font-size: 14px;
    }

    .btn-primary {
        background: #007EFF;
    }

    .btn-secondary,
    .btn-secondary:hover, 
    .btn-secondary:active, 
    .btn-secondary:not(:disabled):not(.disabled).active, 
    .btn-secondary:not(:disabled):not(.disabled):active, 
    .show > .btn-secondary.dropdown-toggle, 
    .btn-secondary.focus, 
    .btn-secondary:focus {
        background: #ffffff00;
        border: 1px solid #1086FF;
        filter: drop-shadow(0px 8px 16px rgba(0, 126, 255, 0.16));
        color: #1086FF;
    }

    .modal-right-side .modal-header {
        border-bottom: 0px;
    }

    .modal-right-side .modal-footer {
        border-top: 0px;
    }

    .modal-right-side .modal-dialog {
        height: 100%;
        width: 100%;
        margin: 0px;
        position: absolute;
        right: 0;
    }

    .modal-right-side .modal-content {
        height: 100%;
        border: 0px;
        border-radius: 0;
        padding: 0 18px;
    }

    .modal-backdrop {
        background: rgba(0, 0, 0, 0.9);
    }

    .modal-backdrop.show {
        opacity: 1;
    }

    .modal-title-icon {
        font-size: 24px;
        float: left;
        position: relative;
        bottom: 1px;
        color: #747373fb;
    }

    .modal-title-content {
        margin-top: 0px;
        margin-left: 20.67px;
    }   

    .modal-title {
        padding: 20px 20px 0 0;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .modal-button {
        position: absolute;
        right: 0;
        margin-right: 29px;
    }

    .form-group, .form-row {
        text-align: left;
    }

    .form-group label, .form-row label {
        color: #52575C;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
    }

    .form-title {
        padding: 13px 13px 4px 4px;
    }

    .form-row {
        margin-bottom: 20px;
    }

    .form-check i {
        padding: 0 10px;
    }

    .btn-link {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color:rgb(2, 117, 216);
        box-shadow: none;
        border-radius: 0px;
        padding: 0px;
        text-decoration: none !important;
    }

    .form-control-plaintext {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .org-page .title {
        padding-top: 0px !important;
    }

    .btn-secondary:hover {
        color: #fff;
        background-color: rgb(2, 117, 216);
        border-color: rgb(2, 117, 216);
    }

    .input-info {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .bootstrap-datetimepicker-widget {
        z-index: 200000;
    }

    .vdatetime-popup__header,
    .vdatetime-calendar__month__day--selected > span > span,
    .vdatetime-calendar__month__day--selected:hover > span > span {
        background:  #007EFF !important;
    }

    .vdatetime-year-picker__item--selected,
    .vdatetime-time-picker__item--selected,
    .vdatetime-popup__actions__button {
        color:  #007EFF !important;
    }

    .loader-background {
        height: 100%;
        width: 100%;
        z-index: 3000000;
        background-color: #000;
        opacity: 0.7;
        position: absolute;
        top: 0;
        text-align: center;
        display: flex;
        justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */
    }

    .loader {
        color: #fff;
        font-size: 25px;
        z-index: 3000000;
    }

    .pre-loader{ 
        z-index: 4000000;
    }

    .dropdown-actions .dropdown-menu a:hover {
        color: #007EFF;
    }

    .dropdown-actions .dropdown-button {
        padding: 10px;
    }

    .btn-outline-primary {
        color: #007EFF;
        border-color: #007EFF;
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active, 
    .btn-outline-primary:not(:disabled):not(.disabled):active, 
    .show > .btn-outline-primary.dropdown-toggle,
    .btn-outline-primary:hover {
        background-color: #007EFF;
        color: #fff;
        border-color: #007EFF;
    }

    textarea.form-control {
        height: 60px;
    }

    .form-group {
        margin-bottom: 5px;
    }

    .badge {
        padding: 6 px;
        padding: 5px 10px;
        color: #F9F9FA;
    }

    .badge-warning {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #F9F9FA;
        background: #F6A609;
        border-radius: 5px;
    }

    .badge-secondary {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
        background: #E5E5E5;
        border-radius: 5px;
    }

    .badge-pill {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.2px;
        color: #161616;
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: #e9ecef5c;
        opacity: 1;
    }

    .dropdown-menu-icon-list .dropdown-item {
        position: relative;
        padding-left: 43px;
        padding-right: 30px;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background: #ecf0f4;
        color: rgb(2, 117, 216);
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px grey;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #e9ecef;
        border-radius: 5px;
    }

    .scrollable-table table {
        width:100%;
    }

    .scrollable-table tbody {
        display: block;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 8%;
    }
    
    .scrollable-table thead, .scrollable-table tbody tr {
        display: table;
        width: 100%;
        table-layout:fixed;
    }
    
    .scrollable-table thead {
        width: calc(100% - 10px);
    }

    .custom-select {
        padding: 0px 10px;
    }

    .modal-body {
        overflow: auto;
    }


    /** 
        OTHER RESOLUTIONS
     */

    @media (max-width: 1300px) {
        
        .footer-container {
            padding-left: 0;
            padding-right: 0;
        }

        .left-side-bar .brand-logo a {
            text-align: left;
            padding: 0px 0px;
        }

        .left-side-bar {
            left: 0;
        }

        .main-container, .footer-container {
            padding-left: 260px;
        }

        .list-container > table > thead > tr > th {
            font-size: 12px;
        }

    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 575px;
        }
    }

    @media (max-width: 1024px){

        .header {
            position: absolute;
        }

        .form-group label, .form-row label { 
            font-size: 15px;
        }

        .col-sm-3 {
            max-width: 32.1%;
        }

        .custom-file-input, .custom-file-label, .custom-select, .form-control { 
            height: 30px;
        }

        .h3, h3 {
            font-size: 20px;
        }

        .h4, h4 {
            font-size: 18px;
        }

        .modal-title-icon {
            font-size: 18px;
        }

        .modal-header {
            padding: 8px 15px;
        }
        
        .modal-body {
            padding-top: 1px;
            overflow: auto;
        }

        .modal-footer {
            padding: 8px .75rem;
        }

        textarea.form-control {
            height: 40px;
        }

        .btn, .modal-footer .btn, .action-btn .btn {
            box-shadow: 0px 8px 16px rgba(161, 175, 189, 0.16);
            border-radius: 8px;
            padding: 6px 18px;
            font-size: 13px;
        }

    }
    
</style>
