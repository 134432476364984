<template>
    <div>
        
        <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>   

        <hr> 

        <div class="cards-container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-12 main-col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title">
                                        <span>
                                            <i class="fa fa-exchange" aria-hidden="true"></i>
                                            Transferir Titualaridade da Organização
                                        </span>
                                    </div>
                                    <div class="card-content">
                                        A transferêcia de titularidade da organização irá alterar sua condição de titular deixando-a a cargo de outros integrantes da organzação de sua escolha.  
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="button" class="btn btn-outline-primary" @click="showTransferModal">
                                        Transferir Titularidade
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 main-col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title">
                                        <span>
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                            Excluir Organização
                                        </span>
                                    </div>
                                    <div class="card-content">
                                        A exclusão da organização vai remover todos os dados e desvincular todos os integrandetes da organização.  
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="button" class="btn btn-outline-danger" @click="showConfirmRemoveModal">
                                        Excluir Organização
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedAccount.trial" class="col-sm-3 main-col">
                    <div class="card card-plan card-plan-trial">
                        <div class="card-body">
                            <div class="card-title">
                                <span>
                                    <i class="logo-plan" aria-hidden="true"><img src="vendors/images/logo-plan.png" alt=""></i>
                                    Seu Plano
                                </span>
                            </div>
                            <div class="card-content">
                                <p>Versão Trial</p>
                                <span>
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    Irá expirar dia {{formatMillisToDateString(selectedAccount.expiresAt)}}
                                </span>
                            </div>
                        </div>
                        <div class="card-footer">
                            <!-- NÃO IMPLEMENTADO AINDA
                            <button type="button" class="btn btn-primary">
                                Solicite um orçamento
                            </button>
                            -->
                        </div>
                    </div>
                </div>
                <div v-if="!selectedAccount.trial" class="col-sm-3 main-col">
                    <div class="card card-packs">
                        <div class="card-body">
                      
                            <div class="card-title">
                                <span>
                                    <i class="fa fa-comments-o" aria-hidden="true"></i>
                                    Disparo de sms
                                </span>
                            </div>
                            <div class="card-content">
                                <div class="packs-explaination">
                                    Seu pacote prevê
                                </div>
                                <div class="packs-stats">
                                    <div class="row">
                                        <span>{{selectedAccount.package.numOfSMSs}}</span> Disparados disponíveis <br/> por mês
                                    </div>
                                </div>
                            </div>
                            
                            <div class="line"> </div>

                            <!-- Visitantes cadastrados -->
                            <div class="card-title">
                                <span>
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    Visitantes Cadastrados
                                </span>
                            </div>
                            <div class="card-content">
                                <div class="packs-explaination">
                                    Seu pacote prevê
                                </div>
                                <div class="packs-stats">
                                    <div class="row">
                                        <span>{{selectedAccount.package.numOfVisitors}}</span> Visitatantes <br/> por mês
                                    </div>
                                </div>
                            </div>
                        </div>
                         <!-- Não tem por enquanto
                        <div class="card-footer">
                            <button type="button" class="btn btn-outline-primary">
                                Como adicionar pacotes?
                            </button>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade transfer-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="transferModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            
                            <span class="modal-title-icon">
                                <i class="micon fa fa-exchange"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>Transferir Titularidade</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        
                        <div class="modal-body-information">
                            Selecione o  titular desta organização
                            <p>({{selectedAccount.name}})</p>
                        </div>

                        <h5>Integrantes ({{team.length}})</h5>

                        <div class="form-check" v-for="(item, index) in team" :key="index">
                            <div class="row">
                                <div class="col-sm-10">
                                    <label class="form-check-label" :for="`team-radio-${item.id}`">
                                        <div class="form-check-name">{{item.name}}</div>
                                        <div class="form-check-email">
                                            <i class="micon fa fa-envelope-o"></i>
                                            {{item.email}}
                                        </div>
                                    </label>
                                </div>
                                <div class="col-sm-2 text-right col-vert-center">
                                    <input class="form-check-input" type="radio" name="team-radio" :id="`team-radio-${item.id}`" @change="selectUserToTranfer($event)" :value="item.id" :checked="selectedUserToTranfer === item.id">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button"  class="btn btn-primary" @click="confirmTransfer" >Transferir</button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-remove 
            id="organization-remove-confirm-modal" 
            question="Excluir Organização?"
            text="Ao excluir, você, todos os integrantes da equipe e convidados perderão acesso aos dados da organização."
            success-message="Organização excluida com sucesso."
            :input-confirm="true"
            :confirm-method="confirmRemoveOrganization"
        ></confirm-remove>

    </div>
</template>

<script>

    import managerService from '../services/ManagerService';
    import MenuService from '../services/MenuService';
    import dateUtils from '../utils/DateUtils';
    import { eventBus } from '../main';

    export default {
        name: 'OrganizationPreferences',
        data: () => ({
            team: [],
            selectedUserToTranfer: ""
        }),
        methods: {
            async confirmTransfer(){
                await managerService.transferOrganization(this.selectedAccount.id, this.selectedUserToTranfer);
                eventBus.$emit('showSuccessMessage', "Organização transferida com sucesso.");
            },
            selectUserToTranfer(event){
                this.selectedUserToTranfer = event.target.value;
            },
            formatMillisToDateString(millis){
                return dateUtils.formatMillisToDateString(millis);
            },
            async showTransferModal(){
                this.team = await managerService.getUsers(this.selectedAccount.id);
                this.selectedUserToTranfer = this.$store.state.userInfo.id;
                window.$('.transfer-modal').modal();
            },
            showConfirmRemoveModal(){
                window.$('#organization-remove-confirm-modal').modal();
            },
            async confirmRemoveOrganization(){
                await managerService.removeOrganization(this.selectedAccount.id);
                this.$store.commit('updateSelectedAccount', null);
                await setTimeout(async () => await this.menuService.goToOrganization() ,1500);
            }            
        },
        async created() {
            this.menuService = new MenuService(this.$router);
            this.selectedAccount = this.$store.state.selectedAccount;
            this.selectedUserToTranfer = this.$store.state.userInfo.id;
        }
    }

</script>

<style scoped>

    .cards-container .card {
        background-color: #ecf0f4;
        height: 100%;
    }
    
    .cards-container {
        padding: 6px 20px;
    }

    .cards-container .card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #161616;
    }

    .cards-container .card-content {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-bottom: 20px;
    }

    .cards-container .row {
        padding-bottom: 18px;
    }

    .cards-container .card-plan-trial {
        background: rgba(246, 166, 9, 0.1);
        border: 1px solid rgba(246, 166, 9, 0.1);
        height: 200px;
    }

    .cards-container .card-plan .logo-plan img {
       height: 18px;
    }

    .cards-container .card-plan .card-content p {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .cards-container .card-plan .card-content span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .cards-container .card-plan .card-content i { 
        color: #F6A609;
    }

    .cards-container .card-footer {
        border-top: 0px;
        background-color: rgba(0,0,0,0);
        padding-top: 0px;
    }

    .cards-container .card-packs .line {
       border-bottom: 1px solid rgba(0, 0, 0, 0.125);
       margin-bottom: 10px;
    }

    .cards-container .card-plan .card-title,
    .cards-container .card-packs .card-title { 
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .cards-container .card-packs .packs-explaination {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding: 8px 0px;
    }

    .cards-container .card-packs .packs-stats {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .cards-container .card-packs .packs-stats .row {
        margin-right: 0px;
        margin-left: 0px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .cards-container .card-packs .packs-stats span {
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-right: 8px;
    }

    .cards-container .card-packs {
        height: auto;
    }

    .main-col {
        padding-left: 8px;
        padding-right: 8px;
    }

    .transfer-modal .modal-body-information {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-bottom: 20px;
    }

    .transfer-modal .modal-body-information p {
        font-weight: 600;
    }

    .transfer-modal .modal-body h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #161616;
        padding-bottom: 40px;
        border-bottom: 1px solid #ecf0f4;
    }

    .transfer-modal .col-vert-center {
        padding-top: 12px;
    }


    .transfer-modal .form-check {
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ecf0f4;
    }

    .transfer-modal .form-check-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .transfer-modal .form-check-email {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .transfer-modal .form-check-email i {
        padding: 0 10px;
        padding-left: 0px;
    }

</style>
