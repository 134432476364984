<template>

    <div class="header" v-bind:class="{ 'org-header' : $router.currentRoute.name === 'Organizations' }">
        
        <div class="header-left">
            <div class="trial-banner" v-if="selectedAccount && selectedAccount.trial">
                <div class="title">Versão Trial</div>
                <div class="date">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    Irá expirar em {{formatMillisToDateString(selectedAccount.expiresAt)}}
                </div>
            </div>
        </div>

        <div class="header-right">
            <div class="user-info-dropdown">
                <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                        <span class="icon-copy ti-user"></span>
                        <span class="user-name">{{loggedUsername}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                        <a class="dropdown-item" href="#" @click="showAppUserModal()">
                            <i class="icon-copy ti-user"></i> 
                            Minha conta
                        </a>
                        <a class="dropdown-item" href="#" @click="logout()">
                            <i class="dw dw-logout"></i> 
                            Sair
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

import { eventBus } from '../main';
import dateUtils from '../utils/DateUtils';

export default {
    name: 'AppHeader',
    data: () => ({
        selectedAccount: {},
        loggedUsername: null,
        formEditUser : {
            oldPassword: "",
            newPassword: "",
            repeatPassword: ""
        }
    }),
    methods: {
        closeApUserModal(){
            window.$('#app-user-modal').modal('hide');
        },
        showAppUserModal(){
            window.$('#app-user-modal').modal();
        },
        async logout(){
            eventBus.$emit('logout');
        },
        formatMillisToDateString(millis){
           return dateUtils.formatMillisToDateString(millis);
        }
    },
    async created() {
        const userInfo = this.$store.state.userInfo;
        if(userInfo){
            this.loggedUsername = this.$store.state.userInfo.name;
        }
        this.selectedAccount = this.$store.state.selectedAccount;
    }
}

</script>


<style scoped>

    .header {
        background: fff;
        height: 80px;
        width: calc(100% - 310px);
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);
        border-bottom: 1px solid rgba(255, 255, 255, 0.199);
    }

    .org-header {
        width: 100%;
    }

    .header-right {
        height: 100%;
    }

    .header-left { 
        height: 100%;
    }

    .user-info-dropdown {
        padding: 0px 10px 0px 0px;
    }

    .user-info-dropdown .dropdown-toggle {
        display: block;
        padding: 30px 20px;
        font-size: 15px;
    }

    .user-info-dropdown .dropdown-toggle .user-name {
        vertical-align: inherit;
        margin-left: 15px;
        margin-right: 15px;
    }

    .trial-banner {
        margin-left: 10px;
        width: 200px;
        height: 54px;
        background: rgba(246, 166, 9, 0.1);
        border-radius: 10px;
        padding: 10px 15px;
    }

    .trial-banner .date i {
        color: #F6A609;
    }

    .trial-banner .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .trial-banner .date {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .org-page  .header  {
        width: 100%;
    }

    /** 
        OTHER RESOLUTIONS
    */

    @media (max-width: 1400px) and (min-width: 1301px) {
        .header {
            width: calc(100% - 220px);
        }
    }

    @media (max-width: 1300px) { 
        .header {
            width: calc(100% - 220px);
        }
    }

</style>