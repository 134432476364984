import axios from 'axios';

class ApiService {

    constructor() {
        this.isAjaxLoading = false;
        this.http = axios.create({
            baseURL: process.env.NODE_ENV === 'dev' ? '' : ''
        });
    }

    async put(url, data, config) {
        return await this.http.put(url, data, config);
    }

    async post(url, data, config) {
        return await this.http.post(url, data, config);
    }

    async get(url, config) {
        return await this.http.get(url, config);
    }

    async delete(url) {
        return await this.http.delete(url);
    }

    showLoading(eventBus) {
        if (!this.isAjaxLoading) {
            eventBus.$emit('showLoadingModal');
            this.isAjaxLoading = true;
        }
    }

    closeLoading(eventBus) {
        eventBus.$emit('closeLoadingModal');
        this.isAjaxLoading = false;
    }

    mountInterceptors(eventBus) {

        this.http.interceptors.request.use((config) => {
                this.showLoading(eventBus);
                return config;
            },
            (error) => {
                return Promise.reject(error);
            });

        this.http.interceptors.response.use((res) => {
            this.closeLoading(eventBus);
            return res;
        }, (err) => {

            this.closeLoading(eventBus);

            if (err.config && err.response && err.response.status === 401) {
                localStorage.removeItem('auth-user');
                return Promise.reject(err);
            }

            if (err.config && err.response && err.response.status === 403 && err.response.data.error) {
                eventBus.$emit('showWarnMessage', err.response.data.error);
                return Promise.reject(err);
            }

            if (err.config && err.response && err.response.status === 400) {
                eventBus.$emit('showErrorMessage', `Error ao tentar realizar esta operação. Detalhe: ${err.response.data.error}`);
                return Promise.reject(err);
            }

            if (err.config && err.response && err.response.status === 404) {
                eventBus.$emit('showWarnMessage', `Recurso não foi encontrado.`);
                return Promise.reject(err);
            }

            eventBus.$emit('showErrorMessage', `Ocorreu um erro inesperado. Detalhe: ${err.response.statusText} ${err.response.status}  <br/>Url: ${err.response.config.url} <br/>Method: ${err.response.config.method}`);

            return new Promise(() => {});

        });

    }

}

export default new ApiService();