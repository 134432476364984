<template>

    <div :id="id" class="modal fade modal-right-side remove-confirm-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="row modal-title">
                        <div class="modal-title-content">
                            <h3>{{question}}</h3>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <p>{{text}}</p>
                    <form :id="`${id}-form`" v-on:submit.prevent="onSubmit" @submit="confirmRemove">
                        <div v-if="inputConfirm" class="form-group">
                            <label class="col-form-label">Para confirmar a exclusão, digite <b>excluir</b></label>
                            <input type="text" class="form-control" v-model="confirmInputData" required>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeConfirmRemoveModal">Cancelar</button>
                    <button type="submit" :form="`${id}-form`" class="btn btn-danger">
                        {{ textButtom ? textButtom : 'Excluir' }}
                    </button>
                </div>
            </div>
            
        </div>
    </div>
    
</template>

<script>

import { eventBus } from '../main';

export default {
    name: 'ConfirmRemove',
    props: [
        'id',
        'question',
        'text', 
        'confirmMethod',
        'successMessage',
        'inputConfirm',
        'textButtom'
    ],
    data: () => ({
        confirmInputData: ""
    }),
    methods: {
        closeConfirmRemoveModal(){
            window.$(`#${this.id}`).modal('hide');
            this.confirmInputData = "";
        },
        async confirmRemove(){
            if(this.inputConfirm && this.confirmInputData !== 'excluir'){
              eventBus.$emit('showWarnMessage', "Texto para exclusão é inválido");
              return;  
            } 
            await this.confirmMethod();
            await this.closeConfirmRemoveModal();
            eventBus.$emit('showSuccessMessage', this.successMessage ? this.successMessage : "Excluido com sucesso.");
            this.confirmInputData = "";
        },
        onSubmit(){}
    },
    async created() {}
}

</script>


<style scoped>

    .remove-confirm-modal {
        z-index: 1051;
    }

    .remove-confirm-modal .modal-title-content {
        margin-left: 0px;
    }

    .col-form-label {
        font-size: 13px;
        color: rgb(255, 0, 0);
    }

</style>