<template>

    <div class="welcome-page">
        
       <h5>Bem vindo ao TOPTIC GO</h5>

    </div>
    
</template>

<script>

export default {
    name: 'Welcome',
    data: () => ({}),
    methods: { },
    async created() {}
}

</script>


<style scoped>

    .welcome-page {
        height: 400px;
        position: relative;
    }

    .welcome-page h5 {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%)
     }

</style>