import apiService from './ApiService';


class ManagerService {

    constructor () {}

    async loadManagerConfig(){
        const response = await apiService.get(`/manager/v1/configs`);
        return response.data;
    }

    async exitOrganization(accountId, userId){
        await apiService.delete(`/manager/v1/accounts/${accountId}/users/${userId}`);
    }

    async transferOrganization(accountId, newOwnerId){
        await apiService.put(`/manager/v1/accounts/${accountId}/transfer`, {newOwnerId : newOwnerId}, {  headers : { 'content-type': 'application/json' }});
    }

    async getInvitedUsers(accountId){
        const response = await apiService.get(`/manager/v1/accounts/${accountId}/invite`);
        return response.data;  
    }

    async removeInvitedUser(accountId, inviteId){
        await apiService.delete(`/manager/v1/accounts/${accountId}/invite/${inviteId}`);
    }

    async getUsers(accountId){
        const response = await apiService.get(`/manager/v1/accounts/${accountId}/users`);
        return response.data.users;  
    }

    async removeUser(accountId, userId){
        await apiService.delete(`/manager/v1/accounts/${accountId}/users/${userId}`);
    }

    async getAccounts(){
        const response = await apiService.get('/manager/v1/accounts/');
        return response.data.accounts;  
    }

    async getInvites(){
        const response = await apiService.get('/manager/v1/user/invites');
        return response.data.invites;  
    }

    async getAccount(accountId){
        return await apiService.get(`/manager/v1/accounts/${accountId}`);  
    }

    async createAccount(data){
        await apiService.post('/manager/v1/account/', data, {  headers : { 'content-type': 'application/json' } });  
    }

    async inviteUser(accountId, data){
        await apiService.post(`/manager/v1/accounts/${accountId}/invite`, data, {  headers : { 'content-type': 'application/json' } });  
    }

    async updatePermissions(accountId, user){
        await apiService.put(`/manager/v1/accounts/${accountId}/users/${user.id}`, { "permissions" : user.permissions } , {  headers : { 'content-type': 'application/json' } });  
    }

    async removeOrganization(accountId){
        await apiService.delete(`/manager/v1/accounts/${accountId}`);  
    }

    async acceptInvite(inviteId){
        await apiService.post(`/manager/v1/user/invites/${inviteId}/accept`);  
    }

    async rejectInvite(inviteId){
        await apiService.post(`/manager/v1/user/invites/${inviteId}/reject`);  
    }

    async getCredentials(accountId){
        const response = await apiService.get(`/manager/v1/accounts/${accountId}/credentials`);
        return response.data.credentials;  
    }

    async saveCredential(accountId, data){
        const response = await apiService.post(`/manager/v1/accounts/${accountId}/credential`, data, {  headers : { 'content-type': 'application/json' } });  
        return response.data;
    }

    async removeCredential(accountId, credentialId){
        await apiService.delete(`/manager/v1/accounts/${accountId}/credentials/${credentialId}`);  
    }

}

export default new ManagerService();