import apiService from './ApiService';
import hash from 'js-sha512';

class UserService {

    constructor () {}

    async removeUser(){
        await apiService.delete('/manager/v1/user/remove');
    }

    async getUserInfo(){
        const response = await apiService.get('/manager/v1/user/info');
        return response.data;    
    }

    async getUserPermissions(accountId, userId){
        const response = await apiService.get(`/manager/v1/accounts/${accountId}/users/${userId}`);
        return response.data.permissions;    
    }

    async changeUserInfo (data){
        await apiService.put('/manager/v1/user/info', data, { headers : { 'content-type': 'application/json' } });
    }

    async changePassword (email, oldPassword, newPassword){
        const data = { 
            oldPassword: hash.sha512(email + oldPassword),  
            newPassword: hash.sha512(email + newPassword)
        };
        await apiService.put('/auth/v1/user/password', data , { headers : { 'content-type': 'application/json' } });
    }

}

export default new UserService();