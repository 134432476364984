<template>
    <div class="modal-dialog">

        <blue-circles/>

        <div class="modal-content">
            
            <form id="form-login-data-modal" v-on:submit.prevent="onSubmit" @submit="login()">
                <div class="modal-body">
                    
                    <div class="login-container"> 
                        
                        <div class="login-logo">
                            <img src="vendors/images/logo-icon.png" alt="">
                        </div>

                        <h3>Acesse sua conta</h3>

                        <div class="form-group row">
                            <label class="col-sm-12">Login</label>
                            <div class="col-sm-12">
                                <input v-model="formLoginData.username" class="form-control" type="text" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-12">Senha</label>
                            <div class="col-sm-12">
                                <input v-model="formLoginData.password" autocomplete="off" class="form-control" type="password" required>
                            </div>
                        </div> 

                        <div class="form-group row">
                            <div class="col-sm-7">
                                <!-- <input class="form-check-input" type="checkbox" id="rebember-me-check" v-model="rememberMe"> -->
                                <!-- <label class="form-check-label" for="rebember-me-check">Lembrar senha</label>                                     -->
                            </div>
                            <div class="col-sm-5 text-right"><!-- -->
                                <label class="form-check-label text-right">
                                    <a class="forgot-pass-btn" @click="showForgotPassModal" href="#">Esqueci a senha</a>
                                </label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-12">
                                <input class="form-check-input" type="checkbox" id="term-check" v-model="termChecked">
                                <label class="form-check-label" for="term-check">
                                    Li e concordo com os <a href="https://topticgo.com/site/termos-de-uso.html" target="_blank">Termos de Uso e Política de Privacidade</a>. 
                                </label>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary" :disabled="!termChecked">
                            Entrar
                        </button>
                        
                        <!-- ******* -->
                        <!-- NÃO PODE CRIAR AINDA A ORG, PQ AINDA NAO TEM A TELA DE CLIAR FLUXO PELO SISTEMA -->
                        <!-- ******* -->
                        <!-- 
                        <div class="register-text">
                            Não tem conta? <a href="#">Cadastre-se</a>
                        </div>
                        -->

                    </div>                  
                    
                </div>
                
            </form>

        </div>
    </div>

</template>

<script>

import { eventBus } from '../main';
import BlueCircles from "./BlueCircles.vue";
import authService from '../services/AuthService';

export default {
    name: 'LoginRegister',
    components: {
        'blue-circles' : BlueCircles
    },
    data: () => ({
        termChecked: false,
        rememberMe: false,
        formLoginData : {
            username: "",
            password: ""
        }
    }),
    methods: {
        showForgotPassModal(){
            const forgotPassModalElm = document.getElementById('forgot-pass-modal');
            forgotPassModalElm.style.display = "block";
            forgotPassModalElm.className += " show";
            this.closeLoginModal();
        },
        showLoginModal(){
            const loginModalElm = document.getElementById('login-modal');
            loginModalElm.style.display = "block";
            loginModalElm.className += " show";
        },
        closeLoginModal(){
            const loginModalElm = document.getElementById('login-modal');
            loginModalElm.style.display = "none";
            loginModalElm.className = loginModalElm.className.replace("show","");
        },
        async login(){

            const login = this.formLoginData.username;
            const password = this.formLoginData.password;

            try {
                await authService.login(login, password);
                localStorage.setItem('auth-user', login);
                location.reload();
            } catch(e){
                eventBus.$emit('showWarnMessage', "Login inválido");
            }

        },
        cleanVuexState(){
            const newState = {};
            Object.keys(this.$store.state).forEach(key => newState[key] = null);
            this.$store.replaceState(newState);
        },
        async logout(){
            try {
                await authService.logout();
            } catch(e){
                console.error(e);
            } finally {
                this.cleanVuexState();
                localStorage.removeItem('auth-user');
                this.showLoginModal();  
            }
        },
        registerLogoutHandling(){
            eventBus.$on('logout', () => this.logout() );
        },
        onSubmit(){}
    },
    async created() {
        await this.registerLogoutHandling();
        const login = localStorage.getItem('auth-user');
        if(!login && !this.$route.query.resetPass){
            this.showLoginModal();
        }
    }
}

</script>


<style scoped>

    .login-logo {
        padding-bottom: 15px;
        margin: 0 55px;
    }

    .login-system-version {
        bottom: 0;
        position: absolute;
        padding: 10px;
    }
   
    #login-modal .modal-dialog {
        max-width: 100%;
        max-height: 100%;
        overflow-y: hidden; 
        overflow-x: hidden;
    }

    #login-modal .modal-content {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }  

    #login-modal .modal-body {
        z-index: 2;
    }

    .login-container {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        width: 494px;
        height: 696px;
        padding: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        margin: 0 auto;
        z-index: 20;
    }

    .login-container .form-check-input {
        margin-left: 2px;
    }

    .login-container .form-check-label {
        padding-left: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #161616;
    }  

    .login-container a {
        color: #1086FF;
    }

    .login-container .btn {
        width: 100%;
        height: 61px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 160%;
        color: #FFFFFF;
        background: #1040C6;
        margin-top: 40px;
    }

    .login-container h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #161616;
        mix-blend-mode: normal;
        margin-top: 18px;
        margin-bottom: 36px;
        text-align: left;
    }

    .login-container .register-text {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2px;
        padding: 25px;
    }

    .forgot-pass-btn {
        color: #161616 !important;
    }

    /** 
        OTHER RESOLUTIONS
    */

    @media (max-width: 1024px) {
        .login-container {
            width: calc(450px ) ;
            height: calc(590px );
        }
    }

</style>