<template>
    
    <div class="org-page">

        <div class="no-orgs" v-if="!orgs.length">
            <p>Você ainda não possui nem está vinculado a nenhuma organização.</p>
            <a>Crie uma para começar a usar a plataforma.</a>
            <div class="no-orgs-image">
                <img src="vendors/images/no-orgs.png" alt="">
            </div>
            <div class="no-orgs-footer">
                <img src="vendors/images/toptic-go-footer.png" alt="">
            </div>
        </div>

        <app-header/>

        <div class="org-main-container">

            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>   

            <h4 class="select-org-text">Selecione ou adicione nova Organização</h4>

            <div class="cards-container">
                    
                <div class="row">
                    <!-- ******* -->
                    <!-- NÃO PODE CRIAR AINDA A ORG, PQ AINDA NAO TEM A TELA DE CLIAR FLUXO PELO SISTEMA -->
                    <!-- ******* -->
                    <!--  
                    <div class="col-sm-3">
                        <div class="card create-card" @click="openOrgModal">
                            <div class="card-body">
                                <span>+</span>
                                <p>Criar nova organização</p>
                            </div>
                        </div>
                    </div>
                    -->
                   
                    <div class="col-sm-3" v-for="(item, index) in orgs" :key="index">
                        <div class="card">
                            <div class="card-body">
                                <div class="row" @click="goHome(item)">
                                    <div class="col-sm-5 card-body-col-left">
                                        <div class="org-logo">
                                            <p v-if="!item.logo">{{item.name.charAt(0)}}</p>
                                            <img v-if="item.logo" :src="'data:image/*;base64,' + item.logo"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-7 card-body-col-right">
                                        <div class="org-name-container">
                                            <div class="org-name">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                    <i class="fa fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                    <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)">
                                        <i class="dw dw-logout"></i> Sair da organização
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="invites-container">
            
            <div class="row title">
                <span class="icon">
                    <i class="micon fa fa-envelope-o"></i>
                </span>
                <div class="content">
                    <h4>Seus Convites</h4>
                </div>
            </div>

            <div class="invites-count">
                <span>{{invitations.length}}</span> Convite pendentes para você
            </div>

            <div v-if="!invitations.length" class="no-invite">
                <p>Você não possui convites para se juntar a nenhuma organização</p>
                <img src="vendors/images/mailbox.png" alt="">
            </div>

            <div class="invites-container-cards">

                <div class="card" v-for="(item, index) in invitations" :key="index">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-5 card-body-col-left">
                                <div class="org-logo">
                                    <p>{{item.account.name.charAt(0)}}</p>
                                </div>
                            </div>
                            <div class="col-sm-7 card-body-col-right">
                                <div class="row invite-text">
                                    {{item.requester.name}} convidou você para a organização <b>{{item.account.name}}</b>
                                </div>
                                <div class="row invite-buttons">
                                    <div class="col-sm-4 left-btn">
                                        <button type="button" class="btn btn-link" href="#" @click="rejectInvite(item)">
                                            Recusar
                                        </button>
                                    </div>
                                    <div class="col-sm-8 right-btn">
                                        <button type="button" class="btn btn-link" href="#" @click="acceptInvite(item)">
                                            Aceitar convite
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="modal fade create-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>Criar Nova Organização</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="organizations-form" v-on:submit.prevent="onSubmit" @submit="createOrg">
                            
                            <div class="form-row form-title">
                                <h5>Logo da organização</h5>
                            </div>

                            <div class="form-group">
                                <label for="recipient-name" class="col-form-label">
                                    Inclua o logotipo de sua organização
                                </label>
                                <div class="upload-container">
                                    <div v-if="organization.logo" class="upload-container-img">
                                        <a href="#" @click="removeOrgImage"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        <img :src="`data:image/*;base64,${organization.logo}`"/>
                                    </div>
                                    <div v-if="!organization.logo" class="upload-container-box">
                                        <div>
                                            <i class="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        <div>
                                            <label class="btn btn-primary">
                                                Selecione uma imagem
                                                <input @change="handleOrgImage" type="file" accept="image/*">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row form-title">
                                <h5>Dados da organização</h5>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-form-label">Nome da organização</label>
                                <input type="text" class="form-control" v-model="organization.name" @change="orgNameChange($event)" required>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">
                                    Label <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="{ title: 'Label será utlizado como subdomínio da organização: Ex: https://label.' + this.domain, placement: 'right' }" ></i>
                                </label>
                                <input type="text" class="form-control" v-model="organization.label" @change="orgLabelChange($event)" required :disabled="!organization.name">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Descrição</label>
                                <textarea type="text" class="form-control" v-model="organization.description" rows="3"></textarea>
                            </div>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" form="organizations-form" class="btn btn-primary">Criar Organização</button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-remove 
            id="org-remove-confirm-modal" 
            question="Sair da organização?"
            success-message="Processo executado com sucesso."
            text-buttom="Sair"
            :input-confirm="false"
            :confirm-method="confirmRemoveOrg"
        ></confirm-remove>

    </div>

</template>

<script>

    import AppHeader from "../components/AppHeader.vue";
    import managerService from '../services/ManagerService';
    import userService from '../services/UserService';
    import MenuService from '../services/MenuService';
    import { eventBus } from '../main';

    export default {
        name: 'Organizations',
        components: {
            'app-header' : AppHeader
        },
        data: () => ({
            organization: {},
            orgs: [],
            invitations: [],
            domain: ""
        }),
        methods: {
            async confirmRemoveOrg(){
                await managerService.exitOrganization(this.selectedOrgToExit.id, this.userInfo.id);
                await this.loadOrgs();
            },
            showConfirmRemoveModal(item){
                this.selectedOrgToExit = item;
                window.$('#org-remove-confirm-modal').modal();
            },
            async acceptInvite(item){
                await managerService.acceptInvite(item.id);
                await this.loadInvites();
                await this.loadOrgs();
            },
            async rejectInvite(item){
                await managerService.rejectInvite(item.id);
                await this.loadInvites();
                await this.loadOrgs();
            },
            orgNameChange(event){
                this.organization.label = this.processLabel(event.target.value);
                this.organization = Object.assign({}, this.organization);
            },
            orgLabelChange(event){
                this.organization.label = this.processLabel(event.target.value);
                this.organization = Object.assign({}, this.organization);
            },
            processLabel(label){
                return label.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "").toLowerCase().trim();
            },
            async loadOrgs(){
                this.orgs = await managerService.getAccounts();
            },
            async loadInvites(){
                this.invitations = await managerService.getInvites();
            },
            openOrgModal(){
                window.$('.create-modal').modal();
            },
            async createOrg(){
                await managerService.createAccount(this.organization);
                await this.loadOrgs();
                window.$('.create-modal').modal('hide');
                this.organization = {};
                eventBus.$emit('showSuccessMessage', "Organização criada com sucesso.");
            },
            handleOrgImage(e){
                
                const reader = new FileReader();

                reader.onload = (evt) => {
                    this.organization.logo = window.btoa(evt.target.result);
                    this.organization = Object.assign({}, this.organization);
                }

                reader.readAsBinaryString(e.target.files[0]);

            },
            removeOrgImage(){
                this.organization.logo = null;
                this.organization = Object.assign({}, this.organization);
            },
            async goHome(item){
                const userPermissions = await userService.getUserPermissions(item.id, this.userInfo.id);
                this.$store.commit('updateUserPermissions', userPermissions );
                this.$store.commit('updateSelectedAccount', item);
                this.menuService.goToHome();
            },
            onSubmit(){}
        },
        async created() {
            this.menuService = new MenuService(this.$router);
            const paramsData = this.$route.params.data;
            if(!paramsData){
                this.menuService.goToHome();
                return;
            }
            await this.loadOrgs();
            await this.loadInvites();
            this.userInfo = this.$store.state.userInfo;
            this.$store.commit('updateSelectedAccount', null);
            this.domain = this.$store.state.managerConfig.domain;
        }
    }

</script>

<style scoped>

    .select-org-text {
        text-align: left;
        padding-left: 24px;
        padding-top: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .org-page {
        display: -moz-box;
        display: -webkit-flexbox;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -moz-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 100vh;
    }

    .org-main-container {
        padding-top: 110px;
        flex: 82%;
    }

    .cards-container {
        padding: 30px 24px;
    }

    .card {
        background: #FFFFFF;
        border: 1px solid #dadada;
        box-shadow: 0px 0px 0px 0px;
        border-radius: 10px;
        height: 150px;
        width: 350px;
        margin-bottom: 25px;
    }

    .invites-container {
        flex: 18%;
        background: #F9F9FA;
        padding: 18px;
    }

    .invites-container .row {
        margin-right: 0;
        margin-left: 0;
    }

    .invites-container .icon {
        margin-right: 7px;
        font-size: 24px;
    }

    .invites-container h4{
        line-height: 40px;
    }

    .invites-container .title {
        padding-top: 100px !important;
        padding-bottom: 20px !important;
        padding-left: 42px;
    }

    .invites-container .card-body-col-left {
        padding-left: 0px;
        padding-left: 0px;
    }

    .invites-container .invites-count {
        padding-bottom: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-left: 42px;
    }

    .invites-container .invites-count span {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .card .card-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .card .card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
        padding-right: 8px;
    }
    .org-logo {
        width: 125px;
        height: 125px;
        background: #F2F5F9;
        border: 3px solid #F2F5F9;
        border-radius: 10px;
        text-align: center;
    }

    .org-logo img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    .org-logo p {
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        color: #161616;
        padding: 10px;
        margin-bottom: 0px;
        text-transform: uppercase;
    }

    .org-name-container {
        padding: 0 15px ;
    }

    .org-name-container .org-name {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        text-align: left;
    }

    .create-card {
        background: #1086FF;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #F9F9FA;
        cursor: pointer;
    }

    .create-card .card-body {
        display: block;
        padding: 32px;
    }

    .card-body { 
        padding: 12px;
    }

    .create-card span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 64px;
        line-height: 54px;
        text-align: center;
    }

    .upload-container {
        text-align:center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 12px;
    }

    .upload-container-box { 
        background: #F7F9FF;
        border: 2px dashed #A0A4A8;
        border-radius: 10px;
        width: 256px;
        height: 256px;
        padding-top: 70px;
    }

    .upload-container-img {
        width: 256px;
        height: 256px;
    }

    .upload-container-img img {
        border-radius: 10px;
        border: 2px solid #A0A4A8;
        height: 256px;
        width: 256px;
    }

    .upload-container input { 
        opacity: 0;
        width: 120px;
        cursor: pointer;
        position: absolute;
    }

    .upload-container label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        text-align: center;
        padding: 15px;
    }

    .upload-container i { 
        padding-bottom: 20px;
        font-size: 40px;
        color: #747373fb;
    }

    .card-body-col-right {
        padding-left: 3px;
    }

    .card-body-col-left {
        padding-right: 3px;
    }

    .no-orgs {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 23%;
        height: 100%;
    }

    .no-orgs p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .no-orgs a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #1086FF;
    }

    .no-orgs-footer {
        position: absolute;
        width: 100%;
        position: fixed;
        bottom: 0;
        padding-bottom: 30px;
    }

    .no-invite {
        padding: 40px 65px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .no-invite img {
        padding-top: 250px;
    }

    .upload-container-img a  {
        position: absolute;
        padding: 5px 8px;
        
    }

    .upload-container-img i {
        font-size: 25px;
        color: #747373fb;
        padding-bottom: 0px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
    }

    .cards-container .card {
        cursor: pointer;
    }

    .invites-container .card .invite-text {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .invites-container .card .invite-buttons .left-btn {
        padding-left: 0px;
    }

    .invites-container .card .invite-buttons .right-btn {
        padding-right: 0px;
    }

    .invites-container .card .invite-buttons .left-btn button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        letter-spacing: 0.2px;
        color: #52575C;
    }
    
    .invites-container .card .invite-buttons .right-btn button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #1086FF;
    }

    .invites-container .card .invite-buttons {
        position: absolute;
        bottom: 0;
    }

    .invites-container .card-body-col-right {
        padding-right: 0px;
    }

    .card .dropdown-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 7px 9px;
    }

    .invites-container-cards {
        overflow-y: scroll;
        height: 100%;
        padding: 10px;
    }

    /** 
        OTHER RESOLUTIONS
    */

    @media (max-width: 1780px) {

        .no-invite img {
            padding-top: 80px;
        }

        .cards-container {
            padding: 30px 5px;
        }
        
        .org-main-container {
            flex: 65%;
        } 
        
        .card {
            width: 235px;
            height: 120px;
        }

        .org-logo {
            width: 95px;
            height: 95px;
        }

        .org-logo p {
            font-size: 50px;
        }

        .org-name-container .org-name {
            font-size: 15px;
        }

        .create-card span {
            font-size: 50px;
        }

        .create-card .card-body {
            padding: 20px;
        }

    }

    @media (max-width: 1024px) {

        .upload-container-box { 
            width: 190px;
            height: 190px;
        }

        .upload-container label {
            font-weight: 500;
            font-size: 14px;
            padding: 10px;
        }

    }

</style>
