import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import Invitations from '../components/Invitations.vue';
import Team from '../components/Team.vue';
import Organizations from '../components/Organizations.vue';
import OrganizationPreferences from '../components/OrganizationPreferences.vue';
import Welcome from '../components/Welcome.vue';
import Responsibles from '../components/Responsibles.vue';
import Visitors from '../components/Visitors.vue';
import Credentials from '../components/Credentials.vue';

Vue.use(VueRouter);

const routes = [
    { 
        path: '*', 
        redirect: '/' 
    },
    {
        path: '/',
        name: 'Home',
        components:  {
            default: Home
        },
        props: true
    },{
        path: '/invitations',
        name: 'Invitations',
        components:  {
            default: Invitations
        },
        props: true
    },{
        path: '/team',
        name: 'Team',
        components:  {
            default: Team
        },
        props: true
    },{
        path: '/organizations',
        name: 'Organizations',
        components:  {
            fullscreen: Organizations
        },
        props: true
    },{
        path: '/organization-preferences',
        name: 'OrganizationPreferences',
        components:  {
            default: OrganizationPreferences
        },
        props: true
    },{
        path: '/welcome',
        name: 'Welcome',
        components:  {
            default: Welcome
        },
        props: true
    },{
        path: '/Responsibles',
        name: 'Responsibles',
        components:  {
            default: Responsibles
        },
        props: true
    },{
        path: '/Visitors',
        name: 'Visitors',
        components:  {
            default: Visitors
        },
        props: true
    },{
        path: '/Credentials',
        name: 'Credentials',
        components:  {
            default: Credentials
        },
        props: true
    }    
    
];

const router = new VueRouter({
    routes: routes
});

export default router;
