import Vue from 'vue';
import App from './App.vue';

import router from './router';
import Vuex from 'vuex';
import VueFilterDateFormat from 'vue-filter-date-format';
import VueTheMask from 'vue-the-mask';

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt';

import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;  

import apiService from './services/ApiService';
import MenuTitle from './components/MenuTitle';
import ConfirmRemove from './components/ConfirmRemove';
import TooltipDirective from './directives/TooltipDirective';
import ScrollableTableDirective from './directives/ScrollableTableDirective';
import PermissionDirective from './directives/PermissionDirective';

Vue.use(Vuex);
Vue.use(VueFilterDateFormat);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

export const eventBus = new Vue();

const store = new Vuex.Store({
    state: {
        appMessages: {},
        selectedAccount: null,
        userInfo: null,
        managerConfig: {}
    },
    mutations: {
        updateAppMessages(state, data) {
            state.appMessages = data;
        },
        updateSelectedAccount(state, data) {
            state.selectedAccount = data;
        },
        updateUserInfo(state, data) {
            state.userInfo = data;
        },
        updateUserPermissions(state, data) {
            state.userPermissions = data;
        },
        updateManagerConfig(state, data) {
            state.managerConfig = data;
        }
    }
});

apiService.mountInterceptors(eventBus);

// COMPONENTES GLOBAIS
Vue.component('menu-title', MenuTitle);
Vue.component('date-time', Datetime);
Vue.component('confirm-remove', ConfirmRemove);

// DIRETIVAS
Vue.directive('permissions', PermissionDirective);
Vue.directive('tooltip', TooltipDirective);
Vue.directive('scrollableTable', ScrollableTableDirective);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
