
import { DateTime } from 'luxon';

class DateUtils {

    constructor () {}

    formatMillisToDateString(millis){
        if(millis){
            return DateTime.fromMillis(millis).toFormat('dd/MM/yyyy');
        }
    }

    formatMillisToDateTimeString(millis){
        if(millis){
            return DateTime.fromMillis(millis).toFormat('dd/MM/yyyy - HH:mm');
        }
    }

    fromMillisToISO(millis){
        if(millis){
            return DateTime.fromMillis(millis).toISO();
        }
    }

    fromISOToMillis(iso){
        if(iso){
            return DateTime.fromISO(iso).toMillis();
        }
    }

    getNowISO(){
        return DateTime.now().toISO();
    }

    getCurrentMonth(){
        const currentMonth = DateTime.now().toFormat('MMMM');
        return currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1, currentMonth.length);
    }

    formatMillisToDateStringForInviteWhatsapp(millis){
        if(millis){
            return DateTime.fromMillis(millis).toFormat("dd/MM/yyyy, 'às' HH:mm");
        }
    }

}

export default new DateUtils();