<template>
    <div class="modal-dialog">

        <blue-circles/>

        <div class="modal-content">
            
            <form id="form-forgot-pass-data-modal" v-on:submit.prevent="onSubmit" @submit="send()">
                <div class="modal-body">
                    
                    <div class="forgot-pass-container"> 
                        
                        <div class="login-logo">
                            <img src="vendors/images/logo-icon.png" alt="">
                        </div>

                        <h3>Esqueceu a senha?</h3>

                        <div class="information-text"> 
                            Não tem problema! <br/>
                            Informe seu e-mail e vamos enviar um link para você criar uma nova senha
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-12">
                                E-mail
                            </label>
                            <div class="col-sm-12">
                                <input type="email" v-model="email" class="form-control" required>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary">
                            Enviar
                        </button>

                    </div>                  
                    
                </div>
                
            </form>

        </div>
    </div>

</template>

<script>

import { eventBus } from '../main';
import BlueCircles from "./BlueCircles.vue";
import authService from '../services/AuthService';

export default {
    name: 'ForgotPass',
    components: {
        'blue-circles' : BlueCircles
    },
    data: () => ({
        email: ""
    }),
    methods: {
        async send(){
            await authService.requestResetPassword(this.email);
            eventBus.$emit('showSuccessMessage', `Um link de para reiniciar sua senha foi enviado para o email informado:\n ${this.email}`, () => {
                location.reload();
            });
        },
        onSubmit(){}
    },
    async created() {}
}

</script>


<style scoped>

    .login-logo {
        padding-bottom: 15px;
        margin: 0 55px;
    }
   
    #forgot-pass-modal .modal-dialog {
        max-width: 100%;
        max-height: 100%;
        overflow-y: hidden; 
        overflow-x: hidden;
    }

    #forgot-pass-modal .modal-content {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }  

    #forgot-pass-modal .modal-body {
        z-index: 2;
    }

    .forgot-pass-container {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        width: 494px;
        height: 570px;
        padding: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        margin: 0 auto;
        z-index: 20;
    }

    .forgot-pass-container a {
        color: #1086FF;
    }

    .forgot-pass-container .btn {
        width: 100%;
        height: 61px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 160%;
        color: #FFFFFF;
        background: #1040C6;
        margin-top: 40px;
    }

    .forgot-pass-container h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #161616;
        mix-blend-mode: normal;
        margin-top: 18px;
        margin-bottom: 36px;
        text-align: left;
    }

    .forgot-pass-btn {
        color: #161616 !important;
    }

    .information-text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-bottom: 25px;
    }

</style>