<template>

    <div class="left-side-bar">
            
        <div class="brand-logo">
            <div class="org-container row">
                <div class="org-logo">
                    <p>{{selectedOrgName.charAt(0)}}</p>
                </div>
                <div class="org-name-container">
                    <div class="org-name">
                        {{selectedOrgName}}
                    </div>
                    <a type="button" class="btn btn-link" href="#" @click="changeOrg">
                        Alterar organização
                    </a>
                </div>
            </div>
        </div>
        
        <div class="menu-block customscroll" >
            <div id="sidebar-menu" class="sidebar-menu">
                <ul v-if="modules">
                
                    <div v-for="(item, index) in modules" :key="index">

                        <li :id="'menu-' + index" class="dropdown">
                            <a  href="#" class="dropdown-toggle" @click="toggleDropdownMenu(index)">
                                <span class="micon icon-copy ion-ios-play"></span>
                                <span class="mtext">{{item.name}}</span>
                            </a>
                            <ul :id="'submenu-' + index" class="submenu">
                                <li v-for="(menu, index) in item.menus" :key="index">
                                    <a href="#" @click="renderContent(menu)">
                                        <span class="micon" v-bind:class="menu.icon"></span>
                                        <span class="mtext">{{menu.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        
                    </div>             
                                    
                </ul>
            </div>
        </div>

        <div class="menu-footer">
            <img src="vendors/images/toptic-go-footer.png" alt="" align="left">
        </div>

    </div>
    
</template>

<script>

import MenuService from '../services/MenuService';
import PermissionService from '../services/PermissionService';

export default {
    name: 'AppMenu',
    data: () => ({
        modules: [],
        selectedOrgName: ''
    }),
    methods: {
        async loadMenus(){          
            const result = await this.menuService.getModules();
            for(const item of result){
                const menus = [];
                for(const menu of item.menus){
                    if(this.permissionService.hasPermissions(menu.permissions)){
                        menus.push(menu);
                    }
                }
                if(menus.length){
                    item.menus = menus;
                    this.modules.push(item);
                }
            }
        },
        renderContent(menu){
            this.menuService.goTo({ name: menu.content, params: { data: menu } });
        },
        toggleDropdownMenu(index){
            window.$(`#menu-${index}`).toggleClass("show");
            window.$(`#submenu-${index}`).toggle();
        },
        changeOrg(){
            this.menuService.goToOrganization();
            this.$store.commit('updateSelectedAccount', null);
        }        
    },
    async created() {
        this.menuService = new MenuService(this.$router);
        this.permissionService = new PermissionService(this.$store.state.userPermissions);
        await this.loadMenus();
        this.selectedOrgName = this.$store.state.selectedAccount.name;
        this.toggleDropdownMenu(0);
    }
}

</script>


<style scoped>

    .sidebar-menu {
        max-width: 310px;
        color: #161616;
    }

    .brand-logo {
        width: 310px;
        height: 80px;
        background:#ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.199);
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    }

    .left-side-bar {
        width: 310px;
        height: 100vh;
        z-index: 1000;
        background:#ffffff;
    }
    
    .sidebar-menu .dropdown-toggle {
        font-weight: 500;
        font-size: 14px;
        padding: 15px 0px 15px 50px;
        text-align: left;
        color: #161616;
        height: 53px;
    }

    .sidebar-menu .submenu li a {
        font-weight: 400;
        text-align: left;
        padding: 15px 15px 18px 45px;
        color: #161616;
        height: 53px;
    }

    .sidebar-menu .submenu li a:hover {
        background: #1086FF;
        color: #ffffff;
    }

    .sidebar-menu .submenu li>a:before {
        top: 37%;
        content: "";
    }

    .sidebar-menu .submenu .micon {
        font-size: 20px;
    }

    .sidebar-menu .dropdown-toggle .micon {
        width: 10px;
        font-size: 20px;
        color: #161616;
        left: 27px;
    }

    .sidebar-menu .dropdown-toggle:after {
        display: none;
    }

    .sidebar-menu ul div li a {
        white-space: normal;
        word-wrap: break-word;
    }

    .sidebar-menu .show .dropdown-toggle .micon {
        top: 5px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .sidebar-menu .dropdown-toggle:hover, 
    .sidebar-menu .show > .dropdown-toggle {
        background: #1086FF;
        color: #ffffff;
    }

    .sidebar-menu .dropdown-toggle:hover .micon,
    .sidebar-menu .show > .dropdown-toggle .micon {
        color: #ffffff;
    }

    .sidebar-menu .submenu .mtext {
        padding: 10px;
        vertical-align: top;
    }

    .menu-footer {
        position: fixed;
        bottom: 0;
        height: 80px;
        width: 100;
        background: #F2F5F9;
        width: 389px;
    }

    .menu-footer img {
        padding: 15px;
    }

    .left-side-bar .menu-block {
        border-bottom: 1px solid rgba(255, 255, 255, 0.199);
        box-shadow: 0 0 5px rgb(0 0 0 / 10%); 
    } 

    .org-logo {
        margin-left: 23px;
        width: 64px;
        height: 64px;
        background: #F2F5F9;
        border: 3px solid #F2F5F9;
        box-sizing: border-box;
        border-radius: 10px;
        text-align: center;
        margin-top: 8px;
    }

    .org-logo p {
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        color: #161616;
        text-transform: uppercase;
    }

    .org-name-container {
        padding: 15px;
        height: 80px;
        margin-top: 2px;
    }

    .org-name-container button {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }

    .org-name-container .org-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        text-overflow: ellipsis;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
    }

    .org-name-container .btn-link {
        font-weight: 500;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #1086FF;
        height: 20px;
    }

    .org-container {
        height: 80px;
        width: 310px;
        margin-left: 0px;
    }

    .org-container .row {
        margin-right: 0px;
        margin-left: 0px;
    }

    .menu-footer {
        width: 310px;
    }

    @media (max-width: 1300px) {

        .org-container {
            width: 260px;
        }
        
        .brand-logo {
            width: 260px;
        }
        
        .menu-footer {
            width: 260px;
        }
        
        .sidebar-menu {
            max-width: 260px;
        }

        .left-side-bar {
            width: 260px;
        }

        .sidebar-menu .submenu li a {
            padding: 15px 15px 18px 14px;
        }

        .sidebar-menu .dropdown-toggle .micon {
            left: 18px;
        }

        .sidebar-menu .dropdown-toggle {
            padding: 15px 0px 15px 42px;
        }

    }

</style>