<template>
    <div>
        
        <div class="header-container">

            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>   

            <div class="modal-button" v-permissions="'MANAGE_USERS'">
                <button type="button" class="btn btn-primary" @click="showInviteUserModal">Convidar novo Integrante</button>
            </div>

            <div class="count-container"> 
                <a class="count-container-tab" v-bind:class="{ 'count-container-tab-border' : isTeamList }" href="#" @click="selectTeamList"> 
                    <span class="count-number">{{team.length}}</span>
                    <span class="count-text" >Ativos</span>
                </a>
                <a class="count-container-tab" v-bind:class="{ 'count-container-tab-border' : !isTeamList }" href="#" @click="selectInvitedUsersList">
                    <span class="count-number">{{invitedUsers.length}}</span>
                    <span class="count-text">Convidados</span>
                </a>
            </div>

            <hr class="line-when-count-container-tab"> 

        </div>

        <div class="list-container">
            <table class="table" v-scrollable-table>
                <thead>
                    <tr>
                        <!-- <th style="width:5%"><input type="checkbox"></th> -->
                        <th style="width:50%" v-if="isTeamList">Nome</th>
                        <th style="width:47%">E-mail</th>
                        <th v-if="!isTeamList">Data Criação</th>
                        <th style="width:3%" v-permissions="'MANAGE_USERS'"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in listData" :key="index">
                        <!-- <td style="width:5%"><input type="checkbox"></td> -->
                        <td style="width:50%" v-if="isTeamList">{{item.name}}</td>
                        <td style="width:47%">{{item.email}}</td>
                        <td v-if="!isTeamList" class="date">{{new Date(item.createdAt) | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                        <td style="width:3%" class="dropdown-actions" v-permissions="'MANAGE_USERS'"> 
                            <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a class="dropdown-item" href="#" @click="showEdit(item)" v-if="isTeamList">
                                    <i class="fa fa-pencil"></i> Editar Permissões
                                </a>
                                <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)" v-show="loggedUserId !== item.id">
                                    <i class="fa fa-trash"></i> Excluir
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade invite-user-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                            <h3>{{user.id ? 'Editar Permissões' : 'Convidar novo integrante' }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="invitations-form" v-on:submit.prevent="onSubmit" @submit="inviteUser">
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-row form-title">
                                        <h5>Dados do Integrante</h5>
                                    </div>
                                    <div class="form-group" v-if="user.id && isTeamList">
                                        <label class="col-form-label">Nome</label>
                                        <input type="text" class="form-control" v-model="user.name" disabled>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">E-mail</label>
                                        <input type="email" class="form-control" v-model="user.email" required :disabled="user.id">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-row form-title">
                                        <h5>Permissões do usuário</h5>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Selecione as permissões do usuário</label>
                                        <div class="form-check" v-for="(permission, index) in permissions" :key="index"> 
                                            <input :id="`${permission.value}_checkbox`" class="form-check-input" @change="changePermission($event)" type="checkbox" :value="permission.value" :checked="isPermissionChecked(permission.value)">
                                            <label class="form-check-label" :for="`${permission.value}_checkbox`">{{permission.label}}</label>
                                            <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="{ title: permission.tooltip, placement: 'right' }" ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button v-if="!user.id" type="submit" form="invitations-form" class="btn btn-primary">Convidar</button>
                        <button v-if="user.id" type="button" class="btn btn-primary" @click="updatePermissions">Salvar</button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-remove 
            v-if="isTeamList"
            id="team-remove-confirm-modal" 
            question="Excluir integrante?"
            success-message="Integrante excluido com sucesso."
            :input-confirm="false"
            :confirm-method="confirmRemoveUser"
        ></confirm-remove>

        <confirm-remove 
            v-if="!isTeamList"
            id="team-remove-confirm-modal" 
            question="Excluir convidado?"
            success-message="Convidado excluido com sucesso."
            :input-confirm="false"
            :confirm-method="confirmRemoveInvitedUser"
        ></confirm-remove>

    </div>
</template>

<script>

    import managerService from '../services/ManagerService';
    import userService from '../services/UserService';
    import PermissionService from '../services/PermissionService';
    import { eventBus } from '../main';

    export default {
        name: 'Invitations',
        data: () => ({
            isTeamList: true,
            listData: [],
            team : [],
            invitedUsers : [],
            user : {
                permissions: []
            },
            permissions: []
        }),
        methods: {
            isPermissionChecked(permission){
                return this.user.permissions.includes(permission);
            },
            async updatePermissions(){
                
                await managerService.updatePermissions(this.selectedAccountId, this.user);

                const userInfo = await userService.getUserInfo();

                await this.closeInviteUserModal();
                await this.loadTeam();

                const successMsg = "Permissões alteradas com sucesso.";

                if(userInfo.email === this.user.email){
                    eventBus.$emit('showSuccessMessage', `${successMsg}<br/>Devido a alteração de suas próprias permissões, o login deverá ser feito novamente.`, () => eventBus.$emit('logout'));
                } else {
                    eventBus.$emit('showSuccessMessage', successMsg);
                }                            
            
            },
            async confirmRemoveUser(){
                await managerService.removeUser(this.selectedAccountId, this.selectedItemToRemove.id);
                await this.selectTeamList();
            },
            async confirmRemoveInvitedUser(){
                await managerService.removeInvitedUser(this.selectedAccountId, this.selectedItemToRemove.id);
                await this.selectInvitedUsersList();
            },
            closeInviteUserModal(){
                window.$('.invite-user-modal').modal('hide');
            },
            showEdit(item){
                this.user = Object.assign({}, item);
                window.$('.invite-user-modal').modal();
            },
            showConfirmRemoveModal(item){
                this.selectedItemToRemove = item;
                window.$('#team-remove-confirm-modal').modal();
            },
            showInviteUserModal(){
                this.user = { permissions: [ 'MANAGE_YOUR_INVITES' ] };
                window.$('.invite-user-modal').modal();
            },
            async inviteUser(){
                await managerService.inviteUser(this.selectedAccountId, this.user);
                await this.closeInviteUserModal();
                eventBus.$emit('showSuccessMessage', "Convite enviado com com sucesso.");
                this.selectInvitedUsersList();
            },
            changePermission(event){
                if(event.target.checked){
                    this.user.permissions.push(event.target.value);
                } else {
                    this.user.permissions = this.user.permissions.filter(element => element !== event.target.value);
                }
            },
            async selectTeamList(){
                await this.loadTeam();
                this.listData = this.team;
                this.isTeamList = true;
                this.loadInvitedUsers();
            },
            async selectInvitedUsersList(){
                await this.loadInvitedUsers();
                this.listData = this.invitedUsers;
                this.isTeamList = false;
                this.loadTeam();
            },
            async loadInvitedUsers(){
                this.invitedUsers = await managerService.getInvitedUsers(this.selectedAccountId);
            },
            async loadTeam(){
                this.team = await managerService.getUsers(this.selectedAccountId);
            },
            onSubmit(){}
        },
        async created() {
            this.selectedAccountId = this.$store.state.selectedAccount.id;
            this.loggedUserId = this.$store.state.userInfo.id;
            const permissionService = new PermissionService(this.$store.state.userPermissions);
            this.permissions = permissionService.getPermissions();
            this.selectTeamList();
            this.loadInvitedUsers();
        }
    }

</script>

<style scoped>

    .date {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #8e949a;
    }

    .form-check {
        padding-left: 22px;
        padding-top: 5px;
    }

    .form-check label { 
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #000000;
    }

    .count-container-tab {
        padding: 10px;
        border-top: 1px solid #ecf0f4;
        border-left: 1px solid #ecf0f4;
        border-right: 1px solid #ecf0f4;
    }

    .count-container-tab-border {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #ecf0f4;
        -webkit-border-radius: 5px 5px 0px 0px; 
        -moz-border-radius: 5px 5px 0px 0px; 
        border-radius: 5px 5px 0px 0px; 
    }

    .line-when-count-container-tab {
        margin-top: 6px;
    }

</style>
