<template>
    <div>
        
        <div class="header-container">
            
            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>  

            <div class="count-container"> 
                <span class="count-number">{{visitors.length}}</span>
                <span class="count-text">Visitantes confirmados</span>
            </div>

            <hr> 

        </div>

        <div class="list-container">
            <table class="table" v-scrollable-table>
                <thead>
                    <tr>
                        <th style="width:19%">Documento</th>
                        <th style="width:26%">Nome</th>
                        <th style="width:20%">E-mail</th>
                        <th style="width:10%">Telefone</th>
                        <th style="width:20%">Responsável</th>
                        <th style="width:12%">Última atualização</th>
                        <th style="width:3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in visitors" :key="index">
                        <td style="width:19%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{item.identification.documentType}}: {{item.identification.value}}
                        </td>
                        <td style="width:26%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{item?.personalData?.name}} {{item?.personalData?.lastName}}
                        </td>
                        <td style="width:20%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{item?.personalData?.emailAddress}}
                        </td>
                        <td style="width:10%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{item?.personalData?.phoneNumber}}
                        </td>
                        <td style="width:20%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{item.additionalData ? item.additionalData.responsible : ''}}
                        </td>
                        <td style="width:12%; cursor: pointer;" @click="showViewVisitorModal(item)">
                            {{new Date(item.lastUpdate) | dateFormat('DD/MM/YYYY HH:mm')}}
                        </td>
                        <td style="width:3%" class="dropdown-actions">
                            <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)">
                                    <i class="fa fa-trash"></i> Excluir
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade view-visitor-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                            <h3>Visualização Visitante</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="photo">
                            <img id="photo-guest" class="rounded mx-auto d-block" :src="guestPhotoToView"/>
                        </div>
                        <div class="visitor">
                            <span><i class="fa fa-user"></i></span>
                            <span>{{selectedItemToView.personalData.name}} {{selectedItemToView.personalData.lastName}}</span>
                        </div>
                        <div class="email">
                            <span><i class="fa fa-envelope-o"></i></span>
                            <span>{{selectedItemToView.personalData.emailAddress}}</span>
                        </div>
                        <div class="phone">
                            <span><i class="fa fa-phone"></i></span>
                            <span>{{selectedItemToView.personalData.phoneNumber}}</span>
                        </div>
                        <div class="data">
                            <div><label>Responsável:</label> {{selectedItemToView.additionalData ? selectedItemToView.additionalData.responsible : ''}}</div>
                            <div><label>{{selectedItemToView.identification.documentType}}:</label> {{selectedItemToView.identification.value}}</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-remove 
            id="visitor-remove-confirm-modal" 
            question="Excluir Visitante?"
            success-message="Visitante excluido com sucesso."
            :input-confirm="true"
            :confirm-method="confirmRemoveVisitor"
        ></confirm-remove>

    </div>
</template>

<script>

    import selfRegisterService from '../services/SelfRegisterService';

    export default {
        name: 'Responsibles',
        data: () => ({
            visitors: [],
            selectedItemToView: {
                personalData: {},
                additionalData: {},
                identification: {}
            },
            guestPhotoToView: null
        }),
        methods: {
            showConfirmRemoveModal(item){
                this.selectedItemToRemove = item;
                window.$('#visitor-remove-confirm-modal').modal();
            },
            async confirmRemoveVisitor(){
                await selfRegisterService.removeGuest(this.selectedAccount.id, this.selectedItemToRemove.id);
                await this.loadVisitors();
            },

            async getPhotoUrl(guestId){
                const arrayBuffer = await selfRegisterService.getGuestPhoto(this.selectedAccount.id,  guestId);
                const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p,c) => p + String.fromCharCode(c) , ''));
                return `data:image/png;base64,${b64encoded}`;
            },

            async showViewVisitorModal(item){
                this.selectedItemToView = item;   
                if(!this.selectedItemToView.personalData){
                    this.selectedItemToView.personalData = {}
                }
                this.guestPhotoToView = await this.getPhotoUrl(this.selectedItemToView.id);
                window.$('.view-visitor-modal').modal();
            },
            async loadVisitors(){
                this.visitors = await selfRegisterService.getGuests(this.selectedAccount.id);
            },
            onSubmit(){}
        },
        async created() {
            this.selectedAccount = this.$store.state.selectedAccount;
            this.loadVisitors();
        }
    }

</script>

<style scoped>

    .view-visitor-modal {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #161616;
    }

    .view-visitor-modal .fa {
        color: #52575C;
        font-size: 20px;
    }   

    .view-visitor-modal .visitor > span i,
    .view-visitor-modal .phone > span i,
    .view-visitor-modal .email > span i {
        width: 30px;
        padding-bottom: 5px;
    }

    .view-visitor-modal .data label {
        color: #52575C;
        font-weight: 600;
        font-size: 16px;
    }

    .view-visitor-modal .data {
        padding-top: 20px;
    }

    .view-visitor-modal .photo {
        width: 270px;
        padding-bottom: 20px;
    }    
    
</style>
