class MenuService {

    constructor (router) {
        this.router = router;
    }

    goTo(data){
        this.router.push(data, () => {});
    }

    goToHome(){
        this.goTo({ 
            name: 'Home',
            params: { data: {} }
        });
    }

    goToOrganization(){
        this.goTo({ 
            name: "Organizations",
            params: { 
                data: {
                    "name": "Suas Organizações",
                    "icon": "fa fa-suitcase"
                },
                fullscreen: true
            }
        });
    }

    goToWelcomePage(){
        this.goTo({ 
            name: 'Welcome',
            params: { data: {} }
        });
    }

    getModules(){
        return [
            {
                name: "Menu Principal",
                menus: [
                    {
                        name: "Painel do Usuário",
                        icon: "fa fa-home",
                        content: "Home",
                        permissions: "MANAGE_ACCOUNT"
                    },
                    {
                        name: "Convites para Eventos",
                        icon: "fa fa-calendar",
                        content: "Invitations",
                        permissions: "MANAGE_INVITES, MANAGE_YOUR_INVITES"
                    },
                    {
                        name: "Equipe",
                        icon: "fa fa-users",
                        content: "Team",
                        permissions: ""
                    },
                    {
                        name: "Visitantes",
                        icon: "fa fa-check-square-o",
                        content: "Visitors",
                        permissions: "MANAGE_GUESTS, MONITOR_GUESTS"
                    }
                ]
            },
            {
                name: "Configurações",
                menus: [
                    {
                        name: "Responsáveis",
                        icon: "fa fa-user",
                        content: "Responsibles",
                        permissions: "MANAGE_INVITES"
                    },
                    {
                        name: "Preferências da Organização",
                        icon: "fa fa-sliders",
                        content: "OrganizationPreferences",
                        permissions: "MANAGE_ACCOUNT"
                    },
                    {
                        name: "Credenciais",
                        icon: "fa fa-key",
                        content: "Credentials",
                        permissions: "MANAGE_CREDENTIALS"
                    }                   
                ]
            }
        ];
    }

}

export default MenuService;