<template>
    <div>
        
        <div class="header-container">
            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>  

            <div class="modal-button">
                <button type="button" class="btn btn-primary" @click="showCreateCredentialModal">Criar nova credencial</button>
            </div>

            <div class="count-container"> 
                <span class="count-number">{{credentials.length}}</span>
                <span class="count-text">Credenciais</span>
            </div>

            <hr> 
        </div>

        <div class="list-container">
            <table class="table" v-scrollable-table>
                <thead>
                    <tr>
                        <th style="width:37%">Credencial</th>
                        <th style="width:45%">Descrição</th>
                        <th style="width:15%">Data Criação</th>
                        <th style="width:3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in credentials" :key="index">
                        <td style="width:37%">{{item.id}}</td>
                        <td style="width:50%">{{item.description}}</td>
                        <td style="width:10%">{{new Date(item.createdAt) | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                        <td style="width:3%" class="dropdown-actions">
                            <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)">
                                    <i class="fa fa-trash" ></i> Excluir
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade create-credential-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                            <h3>{{credential.id ? 'Editar Credencial' : 'Cadastrar nova credencial' }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="credentials-form" v-on:submit.prevent="onSubmit" @submit="save">
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-row form-title">
                                        <h5>Dados da Credencial</h5>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Descrição</label>
                                        <input type="text" class="form-control" v-model="credential.description" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-row form-title">
                                        <h5>Permissões da credencial</h5>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Selecione as permissões do credencial</label>
                                        <div class="form-check" v-for="(permission, index) in permissions" :key="index"> 
                                            <input :id="`${permission.value}_checkbox`" class="form-check-input" @change="changePermission($event)" type="checkbox" :value="permission.value" :checked="isPermissionChecked(permission.value)">
                                            <label class="form-check-label" :for="`${permission.value}_checkbox`">{{permission.label}}</label>
                                            <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="{ title: permission.tooltip, placement: 'right' }" ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" form="credentials-form" class="btn btn-primary">{{credential.id ? 'Editar' : 'Salvar' }}</button>
                    </div>
                </div>
            </div>
        </div>

         <confirm-remove 
            id="credential-remove-confirm-modal" 
            question="Excluir Credencial?"
            success-message="Credencial excluida com sucesso."
            :input-confirm="true"
            :confirm-method="confirmRemoveCredential"
        ></confirm-remove>

    </div>
</template>

<script>

    import managerService from '../services/ManagerService';
    import PermissionService from '../services/PermissionService';
    import { eventBus } from '../main';

    export default {
        name: 'Responsibles',
        data: () => ({
            credentials: [],
            credential: {
                permissions: []
            },
            permissions: []
        }),
        methods: {
            showCreateCredentialModal(){
                this.credential = {
                    permissions: [ 
                        "MONITOR_GUESTS", 
                        "MANAGE_FLOWS" 
                    ]
                };
                window.$('.create-credential-modal').modal();
            },
            async save(){
                const response = await managerService.saveCredential(this.selectedAccount.id, this.credential);
                window.$('.create-credential-modal').modal('hide');
                this.loadCredentials();
                eventBus.$emit('showSuccessMessage', `Credencial cadastrada com com sucesso.<br/><br/><b>Copie sua credencial:<br/><br/>Login:</b> ${response.id}<br/><b>Senha:</b> ${response.password}`);
            },
            async loadCredentials(){
                this.credentials = await managerService.getCredentials(this.selectedAccount.id);
            },
            showConfirmRemoveModal(item){
                this.selectedItemToRemove = item;
                window.$('#credential-remove-confirm-modal').modal();
            },
            async confirmRemoveCredential(){
                await managerService.removeCredential(this.selectedAccount.id, this.selectedItemToRemove.id);
                await this.loadCredentials();
            },
            isPermissionChecked(permission){
                return this.credential.permissions.includes(permission);
            },
            changePermission(event){
                if(event.target.checked){
                    this.credential.permissions.push(event.target.value);
                } else {
                    this.credential.permissions = this.credential.permissions.filter(element => element !== event.target.value);
                }
            },
            onSubmit(){}
        },
        async created() {
            this.selectedAccount = this.$store.state.selectedAccount;
            const permissionService = new PermissionService(this.$store.state.userPermissions);
            this.permissions = permissionService.getPermissions();
            this.loadCredentials();
        }
    }

</script>

<style scoped>

    .form-check {
        padding-left: 22px;
        padding-top: 5px;
    }

    .form-check label { 
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #000000;
    }
    
</style>
