
import PermissionService from '../services/PermissionService';

export default {
    bind: function (el, binding, vnode) {

        const permissions = binding.value;
        const permissionService = new PermissionService(vnode.context.$store.state.userPermissions);

        if(!permissionService.hasPermissions(permissions)){
            el.style.display = 'none';
        }
        
    }
}