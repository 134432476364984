<template>
    <div class="row title">
        <span class="icon">
            <i class="micon" v-bind:class="iconClass"></i>
        </span>
        <div class="content">
            <h3>{{name ? name : 'No Title :(' }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuTitle',
    props: ['name','iconClass']
}
</script>


<style scoped>

    .icon {
        font-size: 24px;
        float: left;
        position: relative;
        bottom: 1px;
        color: #747373fb;
    }

    .content {
        margin-top: 0px;
        margin-left: 20.67px;
    }   

    .title {
        padding: 0 40px;
        height: 35px;
    }

</style>