import { PERMISSIONS } from 'toptic-go-constants';

const LABELS = {
    MANAGE_YOUR_INVITES : "Gerenciar seus convites", 
    MANAGE_ACCOUNT : "Gerenciar organizações",
    MANAGE_USERS : "Gerenciar usuários",
    MANAGE_CREDENTIALS : "Gerenciar credencial de integração",
    MANAGE_GUESTS : "Gerenciar convidados",
    MANAGE_INVITES : "Gerenciar convites",
    MANAGE_ACCESS_POINTS : "Gerenciar pontos de acesso",
    MANAGE_FLOWS : "Gerenciar fluxos de convite",
    MONITOR_GUESTS : "Monitorar convidados"
};

const TOOLTIPS = {
    MANAGE_YOUR_INVITES : "Adicionar, remover ou excluir convites. Você poderá visualizar e alterar apenas os convites que você tiver criado.", 
    MANAGE_ACCOUNT : "Gerenciar as informações da sua organização, como transferência de titularidade ou exclusão da mesma.",
    MANAGE_USERS : "Adicionar, remover ou excluir usuários.",
    MANAGE_CREDENTIALS : "Adicionar, remover ou excluir credenciais. Credenciais são utilizadas para viabilizar a integração entre sistemas.",
    MANAGE_GUESTS : "Alterar, remover ou excluir os visitantes que se auto cadastraram.",
    MANAGE_FLOWS : "Adicionar, remover ou excluir fluxos. Fluxos definem os passos que serão solicitados ao visitante no momento do auto cadastro.",
    MANAGE_INVITES : "Adicionar, remover ou excluir convites. Você poderá visualizar e alterar os convites de todos os usuários.",
    MANAGE_ACCESS_POINTS : "Adicionar, remover ou excluir pontos de acesso.",
    MONITOR_GUESTS : "Possibilitar visualizar os visitantes que se auto cadastram."
};

const EXCLUDE = [ 
    'MANAGE_ACCESS_POINTS', 
    'MANAGE_FLOWS', 
    'MONITOR_GUESTS' 
];

class PermissionService {

    constructor (userPermissions) {
        this.userPermissions = userPermissions;
    }

    hasPermissions(value){
        
        if(!value){
            return true;
        }

        if(!value.includes(",")){
            return this.userPermissions.includes(value.trim());
        }

        const permissions = value.split(",");
    
        for(const permission of permissions){
            if(this.userPermissions.includes(permission.trim())){
                return true;
            }
        }

        return false;

    }

    getPermissions(){

        const permissions = [];

        for(const permission in PERMISSIONS){
            if(!EXCLUDE.includes(permission)){
                permissions.push({ 
                    value: permission, 
                    label: LABELS[permission] || permission,
                    tooltip: TOOLTIPS[permission] || ""
                });
            }
        }

        return permissions;  

    }
    
}

export default PermissionService;