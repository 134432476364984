<template>
    <div>
        
        <div class="header-container">

            <menu-title :name="$route.params.data.name" :icon-class="$route.params.data.icon"></menu-title>  

            <div class="modal-button" v-permissions="'MANAGE_YOUR_INVITES'">
                <button type="button" class="btn btn-primary" @click="showCreateInviteModal">Criar convite</button>
            </div>

            <div class="count-container"> 
                <span class="count-number">{{invitations.length}}</span>
                <span class="count-text">Convites ativos</span>
            </div>

            <hr> 

        </div>

        <div class="list-container">
            <table class="table" v-scrollable-table>
                <thead>
                    <tr>
                        <!-- <th style="width:3%"><input type="checkbox"></th> -->
                        <th style="width:33%">Evento</th>
                        <th style="width:20%">Participantes</th>
                        <th style="width:14%">Início</th>
                        <th style="width:14%">Final</th>
                        <th style="width:16%">Responsável</th>
                        <th style="width:3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in invitations" :key="index">
                        <!-- <td style="width:3%"><input type="checkbox"></td> -->
                        <td style="width:33%" @click="showViewModal(item)" class="event-line">
                            <div class="event-title">{{item.title}}</div>
                            <div class="event-address">
                                <span><i class="fa fa-map-marker"></i></span>
                                <span>{{item.place}}</span>
                            </div>
                        </td>
                        <td style="width:20%" @click="showViewModal(item)" class="attendants">
                            <div class="attendants-text">{{`${item.confirmedGuests} ${item.maxGuests ? ' convidados confirmados de ' + item.maxGuests : 'convidados confirmados'}`}}</div>
                            <div v-if="item.private" class="badge badge-warning">Privado</div>
                            <div v-if="!item.private" class="badge badge-secondary">Aberto ao público</div>
                        </td>
                        <td style="width:14%">{{new Date(item.startDate) | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                        <td style="width:14%" v-if="!item.endDate"></td>
                        <td style="width:14%" v-if="item.endDate">{{new Date(item.endDate)  | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                        <td style="width:16%">{{item.owner.email}}</td>
                        <td style="width:3%" class="dropdown-actions">
                            <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a class="dropdown-item" href="#" @click="showEditInviteModal(item)">
                                    <i class="fa fa-pencil"></i> Editar
                                </a>
                                <a class="dropdown-item" href="#" @click="showConfirmRemoveModal(item)">
                                    <i class="fa fa-trash"></i> Excluir
                                </a>
                                <a v-if="!item.private" class="dropdown-item" href="#" @click="copyInviteLink(item)">
                                    <i class="fa fa-link"></i> Copiar link
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade create-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <span class="modal-title-icon">
                                <i class="micon" v-bind:class="$route.params.data.icon"></i>
                            </span>
                            <div class="modal-title-content">
                                <h3>{{ this.invitation.id ? 'Editar' : 'Criar' }} Convite para Evento</h3>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form id="invitations-form" v-on:submit.prevent="onSubmit" @submit="createInvite">
                            <div class="row">
                                <div class="col-sm">
                                        
                                    <div class="form-row form-title">
                                        <h5>Dados do Evento</h5>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">Titulo</label>
                                        <input type="text" class="form-control" v-model="invitation.title" maxlength="100" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Local do Evento</label>
                                        <input type="text" class="form-control" v-model="invitation.place" maxlength="100" required>
                                    </div>
                                    <div class="form-group" v-permissions="'MANAGE_INVITES'">
                                        <label class="col-form-label">Lista Responsáveis</label>
                                        <select class="form-control custom-select" v-model="invitation.responsibleListId">
                                            <option :value="null" selected>Selecione ...</option> 
                                            <option 
                                                v-for="option in responsibleLists" 
                                                v-bind:value="option.id" 
                                                v-bind:key="option.id"
                                            >
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Data Inicial</label>
                                        <date-time 
                                            input-class="form-control" 
                                            type="datetime" 
                                            v-model="invitation.startDate"
                                            :phrases="{ ok: 'Ok' }"
                                            format="dd/MM/yyyy HH:mm"
                                            auto
                                            required
                                        >
                                            <template slot="button-cancel">
                                                <span class="button-close">Fechar</span>
                                                <span @click="invitation.startDate = null">Limpar</span>
                                            </template>
                                        </date-time>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Data Final</label>
                                        <date-time 
                                            input-class="form-control" 
                                            type="datetime" 
                                            v-model="invitation.endDate"
                                            :phrases="{ ok: 'Ok' }"
                                            format="dd/MM/yyyy HH:mm"
                                            auto
                                        >
                                            <template slot="button-cancel">
                                                <span class="button-close">Fechar</span>
                                                <span @click="invitation.endDate = null">Limpar</span>
                                            </template>
                                        </date-time>
                                    </div>

                                    <div class="form-group">
                                        <div class="form-check"> 
                                            <input id="openEventCheck" class="form-check-input" type="checkbox" @change="changeOpenEventCheck()" v-model="openEventChecked">
                                            <label class="form-check-label" for="openEventCheck">Aberto ao público</label>
                                            <!-- <i class="fa fa-info-circle" v-tooltip="{ title: '', placement: 'right' }" aria-hidden="true"></i> -->
                                            
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm">
                                    <div class="card" v-if="!invitation.private">
                                        <div class="card-body">
                                            <div class="form-row form-title" style="padding-top: 1px">
                                                <h5>Convidados</h5>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Número máximo de participantes</label>
                                                <input type="number" min="1" max="1000" class="form-control" v-model="invitation.maxGuests">
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Dias de validade do acesso</label>
                                                <input type="number" min="1" max="1000" class="form-control" v-model="invitation.period">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="invitation.private && !addingGuests">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-sm">
                                                    <h5>Convidados ({{invitation.guestsInfo.length}})</h5>
                                                </div>
                                                <div class="col-sm">
                                                    <a type="button" class="btn btn-link float-right" href="#" @click="addGuests">
                                                        Adicionar convidado
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body scrollable">
                                            <p class="no-guests" v-if="!invitation.guestsInfo.length">Ainda sem convidados</p>
                                            <div class="row guests-list" v-for="(item, index) in invitation.guestsInfo" :key="index">
                                                <div class="col-sm-9">
                                                    <div class="guest-name">{{item.name}}</div>
                                                    <div class="guest-email" v-if="item.email">
                                                        <span><i class="fa fa-envelope-o"></i></span>
                                                        <span>{{item.email}}</span>
                                                    </div>
                                                    <div class="guest-phone" v-if="item.phone">
                                                        <span><i class="fa fa-phone"></i></span>
                                                        <span>{{item.phone}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2 col-vert-center">
                                                    <span class="badge badge-pill badge-secondary" v-show="item.amountOfGuests">
                                                        +{{item.amountOfGuests - 1}}
                                                        <span><i class="fa fa-user"></i></span>
                                                    </span>
                                                </div>
                                                <div class="col-sm-1 remove-action col-vert-center">
                                                    <a href="#" @click="removeGuest(item)"><i class="fa fa-trash"  ></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="invitation.private && addingGuests">
                                        <div class="card-body">
                                            <form id="guests-form" v-on:submit.prevent="onSubmit" @submit="addGuest">
                                                <div class="form-group" style="padding-top: 4px">
                                                    <h5>Adicionar convidado</h5>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-form-label">Nome do convidado</label>
                                                    <input type="text" class="form-control" v-model="guest.name" required>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-form-label">E-mail</label>
                                                    <input type="email" class="form-control" v-model="guest.email" :required="!guest.phone && !guest.email">
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-form-label">Telefone</label>
                                                    <input type="tel" class="form-control" v-model="guest.phone" @blur="phoneBlur" v-mask="'(##) #####-####'" :required="!guest.phone && !guest.email">
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="guest.hasGuests">
                                                    <label class="form-check-label">Este convidado poderá levar acompanhantes</label>
                                                </div>
                                                <div class="form-group" v-if="guest.hasGuests">
                                                    <label class="col-form-label">Quantos acompanhantes?</label>
                                                    <input type="number" min="1" max="500" class="form-control" v-model="guest.amountOfGuests" required>
                                                </div>
                                                <div class="row adding-guests-btns">
                                                    <div class="col-sm-8">
                                                        <a type="button" class="btn btn-link float-right" href="#" @click="closeAddingGuests">
                                                            Cancelar
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <button type="submit" form="guests-form" class="btn btn-link float-right" href="#">
                                                            Adicionar
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-footer">
                                            <h5>Convidados ({{invitation.guestsInfo.length}})</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer" v-show="!addingGuests">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" form="invitations-form" class="btn btn-primary">{{ this.invitation.id ? 'Editar' : 'Convidar' }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade view-modal modal-right-side" tabindex="-1" role="dialog" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row modal-title">
                            <h3>{{selectedViewItem.title}}</h3>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="address">
                            <span><i class="fa fa-map-marker"></i></span>
                            <span>{{selectedViewItem.place}}</span>
                        </div>
                        <div class="date">
                            <span ><i class="fa fa-clock-o"></i></span>
                            <span class="date-from">{{formatMillisToDateTimeString(selectedViewItem.startDate)}}</span>
                            <span v-if="selectedViewItem.endDate" class="date-to">Até: {{formatMillisToDateTimeString(selectedViewItem.endDate)}}</span>
                        </div>
                        <div>
                            <span v-if="selectedViewItem.private" class="badge badge-warning">Privado</span>
                            <span v-if="!selectedViewItem.private" class="badge badge-secondary">Aberto ao público</span>
                        </div>
                        
                        <div class="confirmed-count">
                            <a class="confirmed-count-tab" href="#" @click="selectConfirmedGuestsTab" v-bind:class="{ 'confirmed-count-tab-border' : isConfirmedSelectedInView || !selectedViewItem.private}">
                                <b>{{selectedViewItem.confirmedGuests}}</b> Confirmados
                            </a>
                            <a v-if="selectedViewItem.private" class="confirmed-count-tab" href="#" @click="selectGuestsTab" v-bind:class="{ 'confirmed-count-tab-border' : !isConfirmedSelectedInView }">
                                <b>{{selectedViewItem.guestsInfo.length}}</b> Convidados
                            </a>
                            <hr class="line-when-count-container-tab"/>
                        </div>

                        <div v-show="!isConfirmedSelectedInView" class="scrollable">
                            <div class="row guests-list" v-for="(item, index) in selectedViewItem.guestsInfo" :key="index">
                                <div class="col-sm-9">
                                    <div class="guest-name">{{item.name}}</div>
                                    <div class="guest-email">
                                        <span v-if="item.email"><i class="fa fa-envelope-o"></i>{{item.email}}</span>  <span v-if="item.phone"><i class="fa fa-phone"></i>{{item.phone}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-vert-center">
                                    <span class="badge badge-pill badge-secondary" v-show="item.amountOfGuests">
                                        +{{item.amountOfGuests - 1}}
                                        <span><i class="fa fa-user"></i></span>
                                    </span>
                                </div>
                                <div class="col-sm-1 dropdown-actions col-vert-center">
                                    <a href="#" class="dropdown-button" role="button" data-toggle="dropdown">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                        <a class="dropdown-item" href="#" @click="shareToWhatsapp(item)" v-if="item.phone">
                                            <i class="fa fa-whatsapp"></i> Compartilhar
                                        </a>
                                        <a v-if="!item.private" class="dropdown-item" href="#" @click="copyPrivateInviteLink(item)">
                                            <i class="fa fa-link"></i> Copiar link
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div v-show="isConfirmedSelectedInView">
                            <div class="row guests-list" v-for="(item, index) in confirmedGuestsList" :key="index">
                                <div class="col-sm-9">
                                    <div v-if="!item?.personalData?.name && !item?.personalData?.lastName" class="guest-name">
                                        {{item.identification.value}}
                                    </div>
                                    <div class="guest-name" v-if="item?.personalData?.name || item?.personalData?.lastName">
                                        {{item.personalData.name}} {{item.personalData.lastName}}
                                    </div>
                                    <div class="guest-document">
                                        {{item.identification.documentType}}: {{item.identification.value}}
                                    </div>
                                    <div class="guest-email">
                                        <span v-if="item?.personalData?.emailAddress">
                                            <i class="fa fa-envelope-o"></i>{{item?.personalData?.emailAddress}}
                                        </span> 
                                        <span v-if="item?.personalData?.phoneNumber">
                                            <i class="fa fa-phone"></i>{{item?.personalData?.phoneNumber}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-vert-center">
                                    <span class="badge badge-pill badge-secondary" v-show="item.amountOfGuests">
                                        +{{item.amountOfGuests - 1}}
                                        <span><i class="fa fa-user"></i></span>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-remove 
            id="invite-remove-confirm-modal" 
            question="Excluir convite?"
            success-message="Convite excluido com sucesso."
            :input-confirm="false"
            :confirm-method="confirmRemoveInvite"
        ></confirm-remove>

    </div>
</template>

<script>

    import selfRegisterService from '../services/SelfRegisterService';
    import { eventBus } from '../main';
    import dateUtils from '../utils/DateUtils';
    import PermissionService from '../services/PermissionService';

    export default {
        name: 'Invitations',
        data: () => ({
            selectedViewItem: { guestsInfo: [] },
            addingGuests : false,
            inviteType: 'email',
            guest: { amountOfGuests: 0 },
            invitations: [],
            invitation: { guestsInfo: [], private: false },
            isConfirmedSelectedInView: true,
            confirmedGuestsList: [],
            responsibleLists: [],
            openEventChecked: true
        }),
        methods: {
            phoneBlur(){
                if(this.guest.phone && this.guest.phone.length < 15){
                    this.guest.phone = "";
                }
            },
            selectConfirmedGuestsTab(){
                this.isConfirmedSelectedInView = true;
            },
            selectGuestsTab(){
                this.isConfirmedSelectedInView = false;
            },
            shareToWhatsapp(item){
                const link = this.buildLink(this.selectedViewItem.id, item.inviteKey).replace(/&/g,'%26');
                const eventDate = this.formatMillisToDateStringForInviteWhatsapp(this.selectedViewItem.startDate);
                const name = encodeURIComponent(this.$store.state.userInfo.name);
                const title = encodeURIComponent(this.selectedViewItem.title);
                const place = encodeURIComponent(this.selectedViewItem.place);
                const text = `Você foi convidado por ${name} para o evento "${title}".%0ALocal: ${place}.%0AData: ${eventDate}.%0APara prosseguir com o cadastro, acesse ${link}`;
                window.open(`https://api.whatsapp.com/send?phone=+55${item.phone}&text=${text}`);  
            },
            copyLink(link){ 
                const clipboardData = window.clipboardData || navigator.clipboard;
                clipboardData.writeText(link);
                eventBus.$emit('showSuccessMessage', `Link copiado com sucesso.`);
            },
            copyInviteLink(item){
                const link = this.buildLink(item.id);
                this.copyLink(link);
            },
            copyPrivateInviteLink(item){
                const link = this.buildLink(this.selectedViewItem.id, item.inviteKey);
                this.copyLink(link);
            },
            buildLink(inviteId, inviteKey){
                const domain = this.$store.state.managerConfig.domain;
                const link = `https://${this.selectedAccount.label}.${domain}/?inviteId=${inviteId}`;
                if(inviteKey){
                    return (link + `&inviteKey=${inviteKey}`);
                }
                return link;
            },
            async confirmRemoveInvite(){
                const invite = this.selectedInviteToRemove;
                await selfRegisterService.removeInvite(invite.accountId, invite.id);
                await this.loadInvitations();
            },
            async showViewModal(item){
                this.selectedViewItem = Object.assign({}, item);
                this.confirmedGuestsList = await selfRegisterService.getGuests(this.selectedAccountId, item.id);
                window.$('.view-modal').modal();
            },
            showConfirmRemoveModal(item){
                this.selectedInviteToRemove = item;
                window.$('#invite-remove-confirm-modal').modal();
            },
            changeOpenEventCheck() {
                this.invitation.private = !this.openEventChecked;
                if(this.invitation.private){
                    delete this.invitation.maxGuests;
                } else {
                    this.invitation.guestsInfo = [];
                }
            },
            async showCreateInviteModal(){
                this.invitation = {
                    guestsInfo: [],
                    private: false,
                    startDate: dateUtils.getNowISO(),
                    responsibleListId: null
                };
                await this.loadResponsibleLists();
                this.openEventChecked = true;
                window.$('.create-modal').modal();
            },
            async showEditInviteModal(item){
                if(item.private){
                    delete item.maxGuests;
                }
                this.invitation = Object.assign({}, item);

                // removendo dados que não devem ir pro servidor, melhorar no futuro!
                delete this.invitation.confirmedGuests;

                this.invitation.startDate = dateUtils.fromMillisToISO(this.invitation.startDate); 
                this.invitation.endDate = dateUtils.fromMillisToISO(this.invitation.endDate);
                await this.loadResponsibleLists();
                this.openEventChecked = !item.private;
                window.$('.create-modal').modal();

            },
            formatMillisToDateTimeString(millis){
                return dateUtils.formatMillisToDateTimeString(millis);
            },
            formatMillisToDateStringForInviteWhatsapp(millis){
                return dateUtils.formatMillisToDateStringForInviteWhatsapp(millis);
            },
            removeGuest(item){
                if(item.email){
                    this.invitation.guestsInfo = this.invitation.guestsInfo.filter( i => i.email !== item.email );
                } else {
                    this.invitation.guestsInfo = this.invitation.guestsInfo.filter( i => i.phone !== item.phone );
                }
            },
            addGuests(){
                this.guest = {
                    amountOfGuests: 0
                }
                this.addingGuests = true;
            },
            addGuest(){
                
                const guest = {
                    name: this.guest.name,
                    email: this.guest.email,
                    phone: this.guest.phone,
                    amountOfGuests: this.guest.amountOfGuests
                };

                if(!guest.email){
                    delete guest.email;
                }

                if(!guest.phone){
                    delete guest.phone;
                }

                if(guest.amountOfGuests){
                    guest.amountOfGuests++;
                } else {
                    guest.amountOfGuests = 1;
                }

                this.invitation.guestsInfo.push(guest);
                this.closeAddingGuests();
            },
            closeAddingGuests(){
                this.addingGuests = false;
                this.guest = {}
            },
            async loadInvitations(){
                this.invitations = await selfRegisterService.getInvites(this.selectedAccountId);
            },
            async createInvite(){
                
                const invitationData = Object.assign({}, this.invitation);
                invitationData.startDate = dateUtils.fromISOToMillis(this.invitation.startDate);
                invitationData.endDate = dateUtils.fromISOToMillis(this.invitation.endDate);

                if(invitationData.id){
                    await selfRegisterService.updateInvite(invitationData);
                } else {
                    await selfRegisterService.createInvite(this.selectedAccountId, invitationData);
                }

                eventBus.$emit('showSuccessMessage', "Convite gerado com sucesso.");
                this.loadInvitations();
                window.$('.create-modal').modal('hide');
            },
            async loadResponsibleLists(){
                if(this.permissionService.hasPermissions('MANAGE_INVITES')){
                    this.responsibleLists = await selfRegisterService.getResponsibles(this.selectedAccountId);
                } else {
                    this.responsibleLists = [];
                }
            },
            onSubmit(){}
        },
        async created() {
            this.permissionService = new PermissionService(this.$store.state.userPermissions);
            this.selectedAccount = this.$store.state.selectedAccount;
            this.selectedAccountId = this.$store.state.selectedAccount.id;
            this.loadInvitations();
            this.guests = [];
        }
    }

</script>

<style scoped>

    .guests-list {
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #E5E5E5;
        margin-right: 0px;
        margin-left: 0px;
    }

    .guests-list .remove-action {
        line-height: 16px;
        padding-left: 10px;
    }

    .guests-list .col-vert-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .guest-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .guest-document {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .guest-email, .guest-phone {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #52575C;
    }

    .guest-phone span {
        padding-right: 7px;
    }

    .guest-email i {
        padding-right: 5px;
    }

    .event-address {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575C;
    }

    .event-address i {
        padding-right: 5px;
    }

    .event-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #161616;
    }

    .event-line {
        cursor: pointer;
    }

    .attendants {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #52575C;
        cursor: pointer;
    }

    .attendants-text {
        padding-bottom: 5px;
    }

    .card {
        height: 100%;
    }

    .card-header, .card-footer {
        background-color: #ffffff;
    }

    .card-body {
        padding-top: 11px;
    }

    .btn-link {
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #1086FF;
    }

    .adding-guests-btns {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-bottom: 60px;
        padding-right: 22px;
        width: 100%;
    }

    .card-footer {
        margin-top: 30px;
    }

    .no-guests {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575C;
        padding-top: 58px;
    }

    .view-modal .address {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #161616;
        margin-bottom: 10px;
    }

    .view-modal .date {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #161616;
        margin-bottom: 10px;
    }

    .view-modal .date-from {
        margin-right: 20px;
    }

    .view-modal .address i {
        padding-right: 11px;
        color: #52575C;
        font-size: 20px;
    }
    
    .view-modal .date i {
        padding-right: 5px;
        color: #52575C;
        font-size: 20px;
    }

    .view-modal .confirmed-count {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575C;
        margin-top: 45px;
        margin-bottom: 30px;
    }

    .form-check {
        padding-left: 22px;
    }

    .confirmed-count-tab {
        padding: 10px;
        border-top: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
    }

    .confirmed-count-tab-border {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #ffffff;
        -webkit-border-radius: 5px 5px 0px 0px; 
        -moz-border-radius: 5px 5px 0px 0px; 
        border-radius: 5px 5px 0px 0px; 
    }

    .line-when-count-container-tab {
        margin-top: 6px;
    }

    .create-modal #invitations-form .scrollable {
        overflow-y: scroll;
        height: 600px;
    }

    .view-modal .scrollable {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 550px;
    }

    .vdatetime-popup__actions__button .button-close {
        margin-right: 40px;
    }

    /** 
        OTHER RESOLUTIONS
    */

    @media (min-width: 1000px) {
        .create-modal .modal-dialog {
            max-width: 1000px;
        }
    }

</style>
